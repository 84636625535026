export default function capitalise(str) {
  // Convert the entire string to lowercase first
  str = str.toLowerCase();

  // Split the string by "-" character
  let parts = str.split("-");

  // Capitalize the first letter of the first part
  parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);

  // Join the parts with a space and return
  return parts.join(" ");
}
