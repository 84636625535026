import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, InputAdornment, OutlinedInput } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import certificate from "../../Assets/certificate.png";
import edit from "../../Assets/edit.png";
import uploadfileIcon from "../../Assets/uploadfile.png";
import capitalise from "../../helper/capitalise";
import AddClinicDialog from "./AddClinicDialog";
import ClinicModal from "./modals/ClinicModal";
import EducationModal from "./modals/EducationModal";
import InformationUpdatedModal from "./modals/InformationUpdatedModal";
import ProfessionalModal from "./modals/ProfessionalModal";
import SpecialityModal from "./modals/SpecialityModal";

function ClinicInformation({ formDataDetails }) {
  const [open, setOpen] = React.useState(false);
  const [submitModal, setSubmitModal] = React.useState(false);
  const [educationModal, setEducationModal] = React.useState(false);
  const [specialityModal, setSpecialityModal] = React.useState(false);
  const [professionalModal, setProfessionalModal] = React.useState(false);
  const [professionalModalError, setProfessionalModalError] =
    React.useState(null);
  const [clinicModal, setClinicModal] = React.useState(false);
  const [collegeName, setCollegeName] = useState("");
  const [passedOutYear, setPassedOutYear] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [savedData, setSavedData] = useState("");
  const [selectedProfessionArray, setSelectedProfessionArray] = useState([]);

  const [formdata, setFormData] = useState({
    clinicName: "",
    jobTitle: "",
    joinMonth: "",
    departure: "",
    joinedYear: "",
    departureMonth: "",
  });

  const [clinicalExperienceArray, setClinicalExperienceArray] = useState([
    {
      clinicName: null,
      clinicNameError: null,
      jobTitle: null,
      jobTitleError: null,
      joinMonth: null,
      joinMonthError: null,
      departure: null,
      departureError: null,
      joinedYear: null,
      joinedYearError: null,
      departureMonth: null,
      departureMonthError: null,
    },
  ]);

  const [submitBtnActive, setSubmitBtnActive] = useState(false);
  const [educationInformationUpdated, setEducationInformationUpdated] =
    useState(false);
  const [speciaityInformationUpdated, setSpeciaityInformationUpdated] =
    useState(false);
  const [professionalInformationUpdated, setProfessionalInformationUpdated] =
    useState(false);
  const [experienceInformationUpdated, setExperienceInformationUpdated] =
    useState(false);
  const [showInformationUpdateModal, setInformationUpdateModal] =
    React.useState(false);

  const handleCloseInformationUpdateModal = () => {
    setInformationUpdateModal(false);
    window.location.reload();
  };

  useEffect(() => {
    if (collegeName != "") {
      // setInformationUpdateModal(true);
      setSubmitBtnActive(true);
    }
  }, [
    collegeName,
    // educationInformationUpdated,
    // speciaityInformationUpdated,
    // professionalInformationUpdated,
    // experienceInformationUpdated,
  ]);

  // useEffect(() => {
  //   if (
  //     educationInformationUpdated &&
  //     speciaityInformationUpdated &&
  //     professionalInformationUpdated &&
  //     experienceInformationUpdated
  //   ) {
  //     // setInformationUpdateModal(true);
  //     setSubmitBtnActive(true);
  //   }
  // }, [
  //   educationInformationUpdated,
  //   speciaityInformationUpdated,
  //   professionalInformationUpdated,
  //   experienceInformationUpdated,
  // ]);

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  const handleEducationClose = () => {
    setEducationModal(false);
  };
  const handleEducationOpen = () => {
    setEducationModal(true);
  };
  const handleSpecialityClose = () => {
    setSpecialityModal(false);
  };
  const handleSpecialityOpen = () => {
    setSpecialityModal(true);
  };
  const handleProfessionalClose = () => {
    setProfessionalModal(false);
  };
  const handleProfessionalOpen = () => {
    setProfessionalModal(true);
  };
  const handleClinicClose = () => {
    setClinicModal(false);
  };
  const handleClinicOpen = () => {
    setClinicModal(true);
  };
  const [first, setFirst] = useState(true);

  const [inputData, setInputData] = useState({});
  const isReset = () => {
    setInputData((prev) => ({
      ...prev,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    isReset();
  };
  const handleCloseSubmit = () => {
    setSubmitModal(false);
  };

  const speciality = [
    "ENDODONTICS",
    "PROSTHODONTICS",
    "OMFS",
    "PEDODONTICS",
    "PERIODONTICS",
    "ORTHODONTICS",
    "OMR",
    "ORAL PATHOLOGY",
    "PUBLIC HEALTH DENTISTRY",
  ];
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const handleSubmitModal = (selectedData) => {
    // Handle the selected data in the parent component
    // console.log("Selected Specialities in Parent: ", selectedData);
    setSelectedSpecialities(selectedData);
    handleSpecialityClose();
  };
  const choices = ["Full time", "Part time", "Consultations"];
  const [selectedProfession, setSelectedProfession] = useState("");

  const handleSubmitProfession = (selectedData) => {
    setSelectedProfession(selectedData);
    handleProfessionalClose();
  };

  const [pdfUrl, setPdfUrl] = useState("");
  const [fileName, setFileName] = useState("");

  const handlePdfUpload = (file) => {
    const url = URL.createObjectURL(file);
    setPdfUrl(url);
    setFileName(file.name);
  };

  const handleDownload = () => {
    if (pdfUrl) {
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.target = "_blank";
      a.click();
      document.body.removeChild(a);
    }
  };
  const handleSave = () => {
    setInformationUpdateModal(true);
    //make call to save user data and display information upadted modal
  };

  useEffect(() => {
    if (formDataDetails.userDetails?.collegeName) {
      let collegeName = formDataDetails.userDetails.collegeName;
      let passOutYear = formDataDetails.userDetails.passOutYear;
      let certificateUrl = formDataDetails.userDetails.certificateUrl;
      if (collegeName) setCollegeName(collegeName);
      if (passOutYear) setPassedOutYear(passOutYear);
      if (certificateUrl) {
        setFileName(certificateUrl);
        setPdfUrl(certificateUrl);
      }
    }

    if (formDataDetails.userDetails?.workType) {
      let workType = formDataDetails.userDetails.workType;
      let arr = formDataDetails.userDetails.workType.split(",");
      arr = arr.map((el) => capitalise(el));
      setSelectedProfession(workType);
      setSelectedProfessionArray(arr);
    }
    if (
      formDataDetails?.userDetails?.specialistIn &&
      formDataDetails?.userDetails?.specialistIn.length > 0
    ) {
      let specialistIn = formDataDetails.userDetails.specialistIn;
      setSelectedSpecialities(specialistIn.split(","));
    }

    // Handling clinic data
    let experiences = [];
    if (
      formDataDetails?.userDetails?.clinicalExpriences &&
      formDataDetails?.userDetails?.clinicalExpriences.length > 0
    ) {
      formDataDetails?.userDetails?.clinicalExpriences?.forEach((el) => {
        const { clinicName, jobTitle, startFrom, upto, id } = el;

        const startDate = startFrom ? new Date(startFrom) : null;
        const endDate = upto ? new Date(upto) : null;

        // Format the start date
        const startMonthYear = startDate
          ? `${startDate.toLocaleString("en-US", {
              month: "long",
            })} ${startDate.getFullYear()}`
          : "";

        // Format the end date
        const endMonthYear = endDate
          ? `${endDate.toLocaleString("en-US", {
              month: "long",
            })} ${endDate.getFullYear()}`
          : "";

        let obj = {
          clinicName: clinicName || "",
          jobTitle: jobTitle || "",
          joinMonth: startMonthYear.split(" ")[0],
          joinedYear: startDate ? startDate.getFullYear() : "",
          departureMonth: endMonthYear.split(" ")[0],
          departure: endDate ? endDate.getFullYear() : "",
          clinicNameError: null,
          jobTitleError: null,
          joinMonthError: null,
          departureError: null,
          joinedYearError: null,
          departureMonthError: null,
          id,
        };

        experiences.push(obj);
      });
    }

    if (experiences.length > 0) {
      setClinicalExperienceArray(experiences);
    }
  }, [
    formDataDetails.userDetails?.collegeName,
    formDataDetails.userDetails?.workType,
    formDataDetails.userDetails?.specialistIn,
  ]);
  //console.log(formDataDetails);
  return (
    <div style={{ fontFamily: "Manrope" }}>
      <div>
        {collegeName || passedOutYear || fileName ? (
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1rem",
              borderRadius: 8,
              marginBottom: "25px",
              position: "relative",
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Button
              sx={{
                color: "#BEBEBE",
                fontWeight: 500,
                fontSize: "13px",
                position: "absolute",
                right: "20px",
                top: "10px",
                width: 69,
                padding: "5px 10px",
                borderRadius: "10px",
                textTransform: "none",
                border: "2px solid #e4e4e4",
              }}
              onClick={handleEducationOpen}
              startIcon={<img src={edit} style={{ height: "18px" }} />}
            >
              Edit
            </Button>
            <div>
              <Typography
                variant="h3"
                fontSize={25}
                style={{ color: "#31445e", fontFamily: "manrope" }}
              >
                Dental Education Information :
              </Typography>

              <div>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ marginTop: "20px", color: "#c5c5c5" }}
                >
                  College Name :
                </Typography>
                <Typography
                  variant="h6"
                  color="#1a2f4c"
                  sx={{
                    borderBottom: "1px solid #dcdcdc",
                    paddingBottom: "2px",
                    fontSize: "18px",
                  }}
                >
                  {collegeName ? collegeName : "College Name"}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="body2"
                  style={{ marginTop: "12px", color: "#c5c5c5" }}
                >
                  Year of Graduation :
                </Typography>
                <Typography
                  variant="h6"
                  color="#1a2f4c"
                  sx={{
                    borderBottom: "1px solid #dcdcdc",
                    paddingBottom: "2px",
                    fontSize: "18px",
                  }}
                >
                  {passedOutYear ? passedOutYear : "Year of Graduation"}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="body2"
                  style={{ marginTop: "12px", color: "#c5c5c5" }}
                >
                  State Council State Certificate :
                </Typography>
                {/* 
                {pdfUrl ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "0.3rem",
                    }}
                  >
                    <Button variant="outlined">Uploaded</Button>
                    <DownloadIcon
                      sx={{ cursor: "pointer" }}
                      onClick={handleDownload}
                    />
                  </div>
                ) : (
                  <span
                    style={{
                      border: "2px dashed #d7d7d7",
                      borderRadius: "1rem",
                      display: "inline-block",
                      padding: "0.5rem",
                      marginTop: "10px",
                    }}
                  >
                    Upload Certificate
                  </span>
                )} */}

                <OutlinedInput
                  inputProps={{ id: "certificateUrl" }}
                  value={pdfUrl ? "Certificate" : ""}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "#FAFAFA",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  placeholder="Upload Document"
                  endAdornment={
                    <InputAdornment position="end">
                      {pdfUrl ? (
                        <DownloadIcon
                          onClick={handleDownload}
                          sx={{ cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          src={uploadfileIcon}
                          alt="Upload Image"
                          style={{ cursor: "pointer" }}
                        />
                      )}

                      {/* <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={uploadFile}
                          ref={fileInputRef}
                        /> */}
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </Card>
        ) : (
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1rem",
              borderRadius: 8,
              marginBottom: "25px",
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "#182E4B", fontWeight: 600, fontSize: "26px" }}
            >
              Dental Education Information :
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "#BEBEBE",
                fontWeight: 400,
                fontSize: "15px",
                mb: 2,
              }}
            >
              Specify Your Dental Education Information
            </Typography>
            <Button
              fullWidth
              // variant="outlined"
              sx={{
                padding: "6px 12px 6px 12px",
                borderRadius: "10px",
                border: "2px solid #2189c5",
                color: "#2189c5",
              }}
              onClick={handleEducationOpen}
            >
              Add Dental Education Information
            </Button>
          </Card>
        )}
        {selectedSpecialities.length > 0 ? (
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1rem",
              borderRadius: 8,
              marginBottom: "25px",
              position: "relative",
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Button
              sx={{
                color: "#BEBEBE",
                fontWeight: 500,
                fontSize: "13px",
                position: "absolute",
                right: "20px",
                top: "10px",
                width: 69,
                padding: "5px 10px",
                borderRadius: "10px",
                textTransform: "none",
                border: "2px solid #e4e4e4",
              }}
              onClick={handleSpecialityOpen}
              startIcon={<img src={edit} style={{ height: "18px" }} />}
            >
              Edit
            </Button>
            <Typography variant="h3" style={{ color: "#31445e" }} fontSize={25}>
              Specialty MDS in :
            </Typography>

            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {selectedSpecialities.map((item, index) => (
                <Typography
                  variant="h6"
                  color="black"
                  borderRadius={10}
                  fontSize={14}
                  sx={{
                    border: "1px solid #d9d9d9",
                    width: "fit-content",
                    padding: "5px 10px",
                  }}
                  key={index}
                >
                  {item}
                </Typography>
              ))}
            </div>
          </Card>
        ) : (
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1rem",
              borderRadius: 8,
              marginBottom: "25px",
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "#182E4B", fontWeight: 600, fontSize: "26px" }}
            >
              MDS Speciality :
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "#BEBEBE",
                fontWeight: 400,
                fontSize: "15px",
                mb: 2,
              }}
            >
              Specify your specialisation
            </Typography>
            <Button
              fullWidth
              // variant="outlined"
              sx={{
                padding: "6px 12px 6px 12px",
                borderRadius: "10px",
                border: "2px solid #2189c5",
                color: "#2189c5",
              }}
              onClick={handleSpecialityOpen}
            >
              Add Specialty
            </Button>
          </Card>
        )}
        {selectedProfessionArray.length > 0 ? (
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1rem",
              borderRadius: 8,
              marginBottom: "25px",
              position: "relative",
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Button
              sx={{
                color: "#BEBEBE",
                fontWeight: 500,
                fontSize: "13px",
                position: "absolute",
                right: "20px",
                top: "10px",
                width: 69,
                padding: "5px 10px",
                borderRadius: "10px",
                textTransform: "none",
                border: "2px solid #e4e4e4",
              }}
              onClick={handleProfessionalOpen}
              startIcon={<img src={edit} style={{ height: "18px" }} />}
            >
              Edit
            </Button>
            <Typography variant="h3" style={{ color: "#31445e" }} fontSize={25}>
              Professional Choices:
            </Typography>

            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {selectedProfessionArray?.map((item, index) => (
                <Typography
                  variant="h6"
                  color="black"
                  borderRadius={10}
                  fontSize={14}
                  sx={{
                    border: "1px solid #d9d9d9",
                    width: "fit-content",
                    padding: "5px 10px",
                  }}
                  key={index}
                >
                  {item}
                </Typography>
              ))}
            </div>
          </Card>
        ) : (
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1rem",
              borderRadius: 8,
              marginBottom: "25px",
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "#182E4B", fontWeight: 600, fontSize: "26px" }}
            >
              Professional Choices :
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "#BEBEBE",
                fontWeight: 400,
                fontSize: "15px",
                mb: 2,
              }}
            >
              Specify Your Professional Choices
            </Typography>
            <Button
              fullWidth
              // variant="outlined"
              sx={{
                padding: "6px 12px 6px 12px",
                borderRadius: "10px",
                border: "2px solid #2189c5",
                color: "#2189c5",
              }}
              onClick={handleProfessionalOpen}
            >
              Add Professional choices
            </Button>
          </Card>
        )}

        {!clinicalExperienceArray[0].clinicName && (
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
              gap: "1rem",
              borderRadius: 8,
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "#182E4B", fontWeight: 600, fontSize: "26px" }}
            >
              Clinical Experience :
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "#BEBEBE",
                fontWeight: 400,
                fontSize: "15px",
                mb: 2,
              }}
            >
              Specify Your Clinical Experience (Clinic Name, Job Tittle,
              Duration.)
            </Typography>
            <Button
              fullWidth
              // variant="outlined"
              sx={{
                padding: "6px 12px 6px 12px",
                borderRadius: "10px",
                border: "2px solid #2189c5",
                color: "#2189c5",
              }}
              onClick={handleClinicOpen}
            >
              Add Clinical Experience
            </Button>
          </Card>
        )}

        {clinicalExperienceArray.length > 0 &&
          clinicalExperienceArray[0].clinicName &&
          clinicalExperienceArray?.map((element) => {
            return (
              <Card
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "1rem",
                  borderRadius: 8,
                  position: "relative",
                  border: "3px solid #f6f6f6",
                  boxShadow: "none",
                }}
              >
                <Button
                  sx={{
                    color: "#BEBEBE",
                    fontWeight: 500,
                    fontSize: "13px",
                    position: "absolute",
                    right: "20px",
                    top: "10px",
                    width: 69,
                    padding: "5px 10px",
                    borderRadius: "10px",
                    textTransform: "none",
                    border: "2px solid #e4e4e4",
                  }}
                  onClick={handleClinicOpen}
                  startIcon={<img src={edit} style={{ height: "18px" }} />}
                >
                  Edit
                </Button>
                <Typography
                  variant="h3"
                  style={{ color: "#31445e" }}
                  fontSize={25}
                >
                  Clinical Experience
                </Typography>

                <div>
                  <Typography variant="body2" style={{ color: "#c5c5c5" }}>
                    Clinic Name :
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "#31445e" }}
                    sx={{ borderBottom: "1px solid gray", fontSize: "18px" }}
                  >
                    {element.clinicName ? element.clinicName : "Clinic Name"}
                  </Typography>
                </div>

                <div>
                  <Typography variant="body2" style={{ color: "#c5c5c5" }}>
                    Job title :
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "#31445e" }}
                    sx={{ borderBottom: "1px solid gray", fontSize: "18px" }}
                  >
                    {element.jobTitle ? element.jobTitle : "Job Title"}
                  </Typography>
                </div>

                <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                  <div style={{ width: "100%" }}>
                    <Typography variant="body2" style={{ color: "#c5c5c5" }}>
                      From
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: "#31445e" }}
                      sx={{ borderBottom: "1px solid gray", fontSize: "18px" }}
                    >
                      {element.joinMonth ? element.joinMonth : "month/"}
                      {element.joinedYear ? element.joinedYear : "year"}
                    </Typography>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Typography variant="body2" style={{ color: "#c5c5c5" }}>
                      To
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: "#31445e" }}
                      sx={{ borderBottom: "1px solid gray", fontSize: "18px" }}
                    >
                      {element.departureMonth
                        ? element.departureMonth
                        : "Month/"}
                      {element.departure ? element.departure : "year"}
                    </Typography>
                  </div>
                </div>
              </Card>
            );
          })}
      </div>

      {/* <AddClinicDialog
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleCloseSubmit={handleCloseSubmit}
        handleClose={handleClose}
        handleSubmitModal={handleSubmitModal}
      /> */}

      <EducationModal
        open={educationModal}
        setOpen={handleEducationOpen}
        handleClose={handleEducationClose}
        setSavedData={setSavedData}
        passedOutYear={passedOutYear}
        setPassedOutYear={setPassedOutYear}
        collegeName={collegeName}
        setCollegeName={setCollegeName}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        savedData={savedData}
        onPdfUpload={handlePdfUpload}
        setEducationInformationUpdated={setEducationInformationUpdated}
        formDataDetails={formDataDetails}
        // onSaveUploadedFile={handleSaveUploadedFile}
      />

      <SpecialityModal
        open={specialityModal}
        setOpen={handleSpecialityOpen}
        handleClose={handleSpecialityClose}
        speciality={speciality}
        onSelectedChange={setSelectedSpecialities}
        handleSubmitModal={handleSubmitModal}
        selectedSpecialities={selectedSpecialities}
        setSpeciaityInformationUpdated={setSpeciaityInformationUpdated}
        formDataDetails={formDataDetails}
      />
      <ProfessionalModal
        open={professionalModal}
        setOpen={handleProfessionalOpen}
        handleClose={handleProfessionalClose}
        choices={choices}
        onSelectedChange={setSelectedProfession}
        handleSubmitModal={handleSubmitProfession}
        addModal={first}
        setProfessionalModalError={setProfessionalModalError}
        setProfessionalInformationUpdated={setProfessionalInformationUpdated}
        setSelectedProfessionArray={setSelectedProfessionArray}
        selectedProfessionArray={selectedProfessionArray}
        formDataDetails={formDataDetails}
      />
      <ClinicModal
        open={clinicModal}
        setOpen={handleClinicOpen}
        handleClose={handleClinicClose}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
        formdata={formdata}
        setClinicalExperienceArray={setClinicalExperienceArray}
        clinicalExperienceArray={clinicalExperienceArray}
        setExperienceInformationUpdated={setExperienceInformationUpdated}
        formDataDetails={formDataDetails}
      />
      <InformationUpdatedModal
        handleCloseInformationUpdateModal={handleCloseInformationUpdateModal}
        showInformationUpdateModal={showInformationUpdateModal}
        formDataDetails={formDataDetails}
      />
      {
        <div style={{ textAlign: "right", margin: "1rem" }}>
          <Button
            disabled={!submitBtnActive}
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "12px",
              backgroundColor: "#0079BF",
              color: "#FFFFFF",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      }
    </div>
  );
}

export default ClinicInformation;
