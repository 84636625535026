import axios from "axios";

import { HttpService } from "./http.service";

// axios.defaults.baseURL = process.env.NEXT_PUBLIC_BACKEND_BASE_URL;

const userSignUp = async (params) => {
  return await HttpService.post(`/api/v1/new-user/signUp`, params);
};

const userLogin = async (formData) => {
  return await HttpService.post(`/api/v1/user/login`, {
    email: formData.email,
    password: formData.password,
  });
};

const getDashboardData = async () => {
  return await HttpService.get(`/api/v1/admin/dashboard/overviews`);
};

const sendOtp = async (email) => {
  return await HttpService.post(`/api/v1/user/forgetPassword/sendOtp`, email);
};

const enterOtp = async (body) => {
  return await HttpService.put(
    `/api/v1/user/otpVarification/forgetPassword`,
    body
  );
};

const updateClinicData = async (body) => {
  return await HttpService.put(`/api/v1/users/settings/edit-details`, body);
};

const getUserDetail = async () => {
  return await HttpService.get(`/api/v1/settings/users-details`);
};

const resetPassword = async (body) => {
  return await HttpService.put(`/api/v1/user/forgotPassword`, body);
};

const verifyOtp = async (args) => {
  return await HttpService.post(`/api/v1/user/verify/otp`, args);
};

const resendOtp = async () => {
  return await HttpService.get(`/api/v1/user/verify/otp/resend`);
};

export default {
  getDashboardData,
  sendOtp,
  enterOtp,
  updateClinicData,
  getUserDetail,
  userLogin,
  userSignUp,
  resetPassword,
  verifyOtp,
  resendOtp
};
