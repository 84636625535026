import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import api from "../api/index";
import goDentyLogo from "../Assets/godentyImage.png";
import sent from "../Assets/sent.png";
import { removeLocalStorageItems } from "../helper/authHelper";
import ForgotPasswordComponent from "./LoginModules/ForgotPasswordComponent";
import { emailValidation } from "./validations/validations";

import "react-toastify/dist/ReactToastify.css";

const GodentyLoginPage = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [inputErrors, setInputErrors] = useState({ email: "", password: "" });
  const [showForgetPasswordComponent, setForgetPasswordComponent] =
    useState(false);

  const [isLoading, setLoading] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);

  const getLogin = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      setInputErrors({
        email: formData.email ? "" : "Please enter a valid email",
        password: formData.password ? "" : "Please enter a password",
      });
      return;
    }
    const emailValidationResp = await emailValidation(formData.email);
    if (!emailValidationResp) {
      setInputErrors({
        ...inputErrors,
        email: "Invalid email",
      });
      return;
    }

    try {
      setLoading(true);
      let response = await api.userLogin(formData);
      setLoading(false);

      if (response?.data?.success === true) {
        // setSubmitModal(true);
        localStorage.setItem("auth", JSON.stringify(response.data.data.token));
        let userRole = response.data.data?.userData._role;
        localStorage.setItem("role", userRole);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.data?.userData)
        );

        //redirect to otp verification
        if (response?.data?.data?.userData?.isVerified == 0) {
          navigate("/verify-otp");
          return;
        }

        if (
          response?.data?.data?.userData?.approvalStatus == "PENDING" &&
          userRole === 3
        ) {
          if (response?.data?.data?.userData?.designation == "BDS") {
            navigate("/bds/profile");
          } else {
            navigate("/mds/profile");
          }
        } else if (
          response?.data?.data?.userData?.approvalStatus == "PENDING" &&
          userRole === 2
        ) {
          navigate("/clinic/profile");
        } else if (
          response?.data.data?.userData?.approvalStatus == "APPROVED"
        ) {
          let userRole = localStorage.getItem("role");
          let authToken = localStorage.getItem("auth");
          let designation = response?.data?.data?.userData?.designation || "";
          removeLocalStorageItems();
          // window.location.href = `http://localhost:3001?role=${userRole}&auth=${authToken}`;
          window.location.href = `https://admin.godenty.com?role=${userRole}&auth=${authToken}&designation=${designation}`;
        } else {
          //if it is not first logged in and it is not approved then we notify user about the review profile inforamtion
          handleClick();
        }
        setFormData({});
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const addFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setInputErrors({ ...inputErrors, [name]: "" });
  };
  const handleCloseSubmit = () => {
    setSubmitModal(false);
    navigate("/");
  };
  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
      <div className="SignUpPage-leftSidecontainer">
        <Link to="/" style={{ textAlign: "center" }}>
          <img src={goDentyLogo} alt="goDentyLogo" style={{ width: "35%" }} />
        </Link>
        <h3 style={{ color: "white", marginTop: "auto" }}>
          Unlock dental career possibilities with us – where hiring meets
          convenience.
        </h3>
      </div>
      <Dialog
        onClose={handleCloseSubmit}
        aria-labelledby="customized-dialog-title"
        open={submitModal}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            p: 2,
            borderRadius: "40px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseSubmit}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "20px",
          }}
        >
          <img
            src={sent}
            alt="Sent"
            style={{ width: "100px", height: "100px" }}
            // className={classes.image}
          />
          {/* <Typography
            align="center"
            // className={classes.text}
            sx={{ fontSize: "40px", fontWeight: "600", color: "#00A01B" }}
          >
            Sent
          </Typography> */}
          <Typography
            align="center"
            sx={{
              marginTop: "10px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            Thank you for joining us!
          </Typography>
          <Typography
            align="center"
            sx={{
              marginTop: "10px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            Your profile is under review. We will get back to you soon.
          </Typography>
        </DialogContent>
      </Dialog>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        {!showForgetPasswordComponent ? (
          <div
            className="signUp-formcontainer"
            style={{ position: "relative" }}
          >
            <form onSubmit={getLogin}>
              <h1 style={{ textAlign: "center" }}>Login</h1>
              <h4>👋 Welcome to Godenty </h4>
              <p style={{ color: "rgba(190, 190, 190, 1)", fontSize: 13 }}>
                Access your account by logging in.
              </p>
              <input
                style={{ marginTop: "15px", marginBottom: "0px" }}
                className="signUp-formcontainer-input"
                name="email"
                type="text"
                onChange={addFormData}
                value={formData.email || ""}
                placeholder="Enter Registered Email  "
              />
              {inputErrors.email && (
                <span className="errorText">{inputErrors.email}</span>
              )}

              <div
                style={{
                  position: "relative",
                  marginTop: "15px",
                }}
              >
                <input
                  style={{ marginBottom: "0px" }}
                  className="signUp-formcontainer-input"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password || ""}
                  onChange={addFormData}
                  placeholder="Enter Password  "
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translate(0, -50%)",
                    right: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </div>
              </div>
              {inputErrors.password && (
                <span className="errorText">{inputErrors.password}</span>
              )}

              <span
                style={{
                  color: "rgba(55, 159, 255, 1)",
                  cursor: "pointer",
                  textAlign: "center",
                  position: "absolute",
                  left: "30px",
                  top: "65%",
                  whiteSpace: "nowrap",
                  fontSize: 13,
                }}
                onClick={() => setForgetPasswordComponent(true)}
              >
                Forgot Password
              </span>

              <button
                type="submit"
                disabled={isLoading}
                style={{
                  width: "100%",
                  padding: "1rem",
                  borderRadius: "12px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "rgba(0, 121, 191, 1)",
                  color: "white",
                  marginTop: "10%",
                  marginBottom: "15px",
                }}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : (
                  "Login"
                )}
              </button>
            </form>

            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                flexWrap: "wrap",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              <p
                style={{
                  color: "rgba(190, 190, 190, 1)",
                  textAlign: "center",
                  fontSize: 13,
                }}
              >
                Don’t have an account?
                <Link to="/signup">
                  <span
                    style={{
                      color: "rgba(55, 159, 255, 1)",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                  >
                    &nbsp;Sign up
                  </span>
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <ForgotPasswordComponent
            setForgetPasswordComponent={setForgetPasswordComponent}
          />
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="User Information has been updated,it is under review!"
      />
    </div>
  );
};
export default GodentyLoginPage;
