import React, { useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import certificate from "../../Assets/certificate.png";
import { Button, InputAdornment, OutlinedInput } from "@mui/material";
import AddClinicDialog from "./AddClinicDialog";
import edit from "../../Assets/edit.png";
import InformationUpdatedModal from "./InformationUpdatedModal";
import DownloadIcon from "@mui/icons-material/Download";
import uploadfileIcon from "../../Assets/uploadfile.png";

function ClinicInformation({ first, clinicInformation }) {
  const [open, setOpen] = React.useState(false);
  const [submitModal, setSubmitModal] = React.useState(false);
  const [showInformationUpdateModal, setInformationUpdateModal] =
    React.useState(false);

  const handleCloseInformationUpdateModal = () => {
    setInformationUpdateModal(false);
    window.location.reload();
  };

  const downloadLicense = () => {
    const pdfUrl = clinicInformation?.licenseUrl;
    let a = document.createElement("a");
    a.href = pdfUrl;
    a.download = "document.pdf";
    document.body.appendChild(a);
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
  };

  const [inputData, setInputData] = useState({});
  const isReset = () => {
    setInputData((prev) => ({
      ...prev,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    isReset();
  };
  const handleCloseSubmit = () => {
    setSubmitModal(false);
  };

  return (
    <div style={{ fontFamily: "Manrope" }}>
      {!first ? (
        <div>
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
              gap: "1rem",
              borderRadius: 8,
              marginBottom: "25px",
              position: "relative",
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Button
              sx={{
                color: "#BEBEBE",
                fontWeight: 500,
                fontSize: "13px",
                position: "absolute",
                right: "20px",
                top: "10px",
                width: 69,
                padding: "5px 10px",
                borderRadius: "10px",
                textTransform: "none",
                border: "2px solid #e4e4e4",
              }}
              startIcon={
                <img src={edit} style={{ width: "24px", height: "24px" }} />
              }
              onClick={() => setOpen(true)}
            >
              Edit
            </Button>
            <Typography
              variant="h3"
              fontSize={25}
              style={{ color: "#31445e", fontFamily: "manrope" }}
            >
              Clinic Information :
            </Typography>

            <div>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginTop: "20px", color: "#c5c5c5" }}
              >
                Clinic Name :
              </Typography>
              <Typography
                variant="h6"
                color="#1a2f4c"
                sx={{
                  borderBottom: "1px solid #dcdcdc",
                  paddingBottom: "2px",
                  fontSize: "18px",
                }}
              >
                {clinicInformation?.clinicName}
              </Typography>
            </div>

            <div>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginTop: "20px", color: "#c5c5c5" }}
              >
                Owner Name :
              </Typography>
              <Typography
                variant="h6"
                color="#1a2f4c"
                sx={{
                  borderBottom: "1px solid #dcdcdc",
                  paddingBottom: "2px",
                  fontSize: "18px",
                }}
              >
                {clinicInformation?.name}
              </Typography>
            </div>

            <div>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginTop: "20px", color: "#c5c5c5" }}
              >
                Contact Number :
              </Typography>
              <Typography
                variant="h6"
                color="#1a2f4c"
                sx={{
                  borderBottom: "1px solid #dcdcdc",
                  paddingBottom: "2px",
                  fontSize: "18px",
                }}
              >
                {clinicInformation?.phoneNumber}
              </Typography>
            </div>

            <div>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginTop: "20px", color: "#c5c5c5" }}
              >
                Email ID :
              </Typography>
              <Typography
                variant="h6"
                color="#1a2f4c"
                sx={{
                  borderBottom: "1px solid #dcdcdc",
                  paddingBottom: "2px",
                  fontSize: "18px",
                }}
              >
                {clinicInformation?.email}
              </Typography>
            </div>

            <div>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginTop: "20px", color: "#c5c5c5" }}
              >
                Website :
              </Typography>
              <Typography
                variant="h6"
                color="#1a2f4c"
                sx={{
                  borderBottom: "1px solid #dcdcdc",
                  paddingBottom: "2px",
                  fontSize: "18px",
                }}
              >
                {clinicInformation?.website}
              </Typography>
            </div>

            <div>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginTop: "20px", color: "#c5c5c5" }}
              >
                Clinic Address :
              </Typography>
              <div>
                <Typography
                  variant="h6"
                  color="#1a2f4c"
                  sx={{
                    borderBottom: "1px solid #dcdcdc",
                    paddingBottom: "2px",
                    fontSize: "18px",
                  }}
                >
                  {clinicInformation?.address}
                </Typography>

                <Typography
                  variant="h6"
                  color="#1a2f4c"
                  sx={{
                    borderBottom: "1px solid #dcdcdc",
                    paddingBottom: "2px",
                    fontSize: "18px",
                  }}
                >
                  {clinicInformation?.area}
                </Typography>

                {/* <Typography
              variant="h6"
              color="text.secondary"
              sx={{
                border: "1px solid blue",
                color: "#0079BF",
                fontSize: "12px",
                padding: "1rem",
              }}
            >
              View on Google Map
            </Typography> */}
              </div>
            </div>

            <div>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginTop: "20px", color: "#c5c5c5" }}
              >
                State Council State Certificate :
              </Typography>

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0.3rem",
                }}
              >
                <Button variant="outlined">Uploaded</Button>
                <DownloadIcon
                  sx={{ cursor: "pointer" }}
                  onClick={downloadLicense}
                />
              </div> */}
              <OutlinedInput
                inputProps={{ id: "certificateUrl" }}
                value={clinicInformation?.licenseUrl ? "License" : ""}
                sx={{
                  borderRadius: 2,
                  backgroundColor: "#FAFAFA",
                  "& .MuiOutlinedInput-input": {
                    borderRadius: 2,
                    border: "none",
                    outline: "none",
                    height: "15px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 2,
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                placeholder="Upload Document"
                endAdornment={
                  <InputAdornment position="end">
                    {clinicInformation?.licenseUrl ? (
                      <DownloadIcon
                        onClick={downloadLicense}
                        sx={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={uploadfileIcon}
                        alt="Upload Image"
                        style={{ cursor: "pointer" }}
                      />
                    )}

                    {/* <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={uploadFile}
                          ref={fileInputRef}
                        /> */}
                  </InputAdornment>
                }
              />
            </div>
          </Card>
        </div>
      ) : (
        <>
          <Card
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
              gap: "1rem",
              borderRadius: 8,
              marginBottom: "25px",
              border: "3px solid #f6f6f6",
              boxShadow: "none",
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "#182E4B", fontWeight: 600, fontSize: "26px" }}
            >
              Clinic Information :
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "#BEBEBE",
                fontWeight: 400,
                fontSize: "15px",
                mb: 2,
              }}
            >
              Specify Your Clinic Details (Clinic Name, Clinic Owner, Clinic
              Contact Number, Clinic Email ID, Clinic Website)
            </Typography>
            <Button
              fullWidth
              // variant="outlined"
              sx={{
                padding: "6px 12px 6px 12px",
                borderRadius: "10px",
                border: "2px solid #2189c5",
                color: "#2189c5",
              }}
              onClick={handleClickOpen}
            >
              Add Clinic Information
            </Button>
          </Card>
        </>
      )}
      <AddClinicDialog
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleCloseSubmit={handleCloseSubmit}
        handleClose={handleClose}
        setInformationUpdateModal={setInformationUpdateModal}
        clinicInformation={clinicInformation}
      />
      <InformationUpdatedModal
        handleCloseInformationUpdateModal={handleCloseInformationUpdateModal}
        showInformationUpdateModal={showInformationUpdateModal}
      />
    </div>
  );
}

export default ClinicInformation;
