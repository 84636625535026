import BdsMdsHeader from "./BdsMdsHeader";
import "../Homepages/styles.css";
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Box, Grid, Button } from "@mui/material";
import ProfileInformation from "../Mdsprofile/ProfileInformation";
import ClinicInformation from "../Mdsprofile/ClinicInformation";
import logout from "../../Assets/logout.png";
import api from "../../api";
import { getToken } from "../../helper/authHelper";
function MdsGraduateFisrtLoginPage() {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const getUserDetail = async () => {
      try {
        let res = await api.getUserDetail();
        if (res?.data?.success) {
          setFormData(res.data.data.user);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getUserDetail();
  }, []);
  return (
    //navbar
    <>
      <div className="homecontainerFirstLoginPage">
        <BdsMdsHeader />
        <Grid
          container
          spacing={1}
          gap="20px"
          sx={{ p: 3, marginTop: "100px" }}
          justifyContent="center"
        >
          <Grid item width={399}>
            <ProfileInformation />
          </Grid>
          <Grid item width={743}>
            <ClinicInformation formDataDetails={formData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default MdsGraduateFisrtLoginPage;
