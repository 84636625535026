import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import closeBtn from "../../Assets/closeBtn.svg";
import cloudAdd from "../../Assets/cloudAdd.png";
import pdfIcon from "../../Assets/pdf.svg";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddClinicDialog = ({
  open,
  handleClose,
  setOpen,
  handleClickOpen,
  handleCloseSubmit,
  handleSubmitModal,
}) => {
  const [certificate, setCertificate] = useState("");

  console.log(certificate);
  const closeImage = () => {
    console.log("called");
    setCertificate("");
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            borderRadius: "40px",
            //  background: "#DBDBDB",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontWeight: 600,
            fontSize: "26px",
            color: "#182E4B",
          }}
          id="customized-dialog-title"
        >
          Add - Clinic Information
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Name"
            // inputProps={{ id: "experience" }}
            // value={inputData.experience}
            // onChange={(e) => {
            //   handleInput(e, "experience");
            // }}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <OutlinedInput
            fullWidth
            placeholder="Enter Name Of Clinic Head"
            // inputProps={{ id: "experience" }}
            // value={inputData.experience}
            // onChange={(e) => {
            //   handleInput(e, "experience");
            // }}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Contact Number"
            // inputProps={{ id: "experience" }}
            // value={inputData.experience}
            // onChange={(e) => {
            //   handleInput(e, "experience");
            // }}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Email ID "
            // inputProps={{ id: "experience" }}
            // value={inputData.experience}
            // onChange={(e) => {
            //   handleInput(e, "experience");
            // }}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Address"
            // inputProps={{ id: "experience" }}
            // value={inputData.experience}
            // onChange={(e) => {
            //   handleInput(e, "experience");
            // }}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          {!certificate ? (
            <Box
              sx={{
                background: "#FFFFFF",
                borderRadius: "12px",
                p: 5,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={cloudAdd}
                style={{ height: "46px", width: "46px" }}
                alt="cloud-icon"
              />
              <Typography
                variant="h3"
                sx={{ color: "#A5A5A5", fontWeight: 500, fontSize: "18px" }}
              >
                Drag and drop
              </Typography>

              <Typography
                variant="h3"
                sx={{ color: "#182E4B", fontWeight: 400, fontSize: "18px" }}
              >
                Council Reg Certificate here
              </Typography>

              <input
                type="file"
                accept="application/pdf"
                style={{
                  textAlign: "center",
                  opacity: 0,
                  position: "relative",
                  left: "10px",
                  top: "30px",
                  width: "250px",
                  height: "30px",
                }}
                onChange={(e) => {
                  // console.log(e.target.files[0]);
                  let data = URL.createObjectURL(e.target.files[0]);
                  console.log(data);
                  setCertificate(data);
                }}
              />
              {/* <Button
              component="label"
              variant="contained"
              sx={{
                textTransform: "none",
                font: "14px",
                fontWeight: "400px",
              }}
            >
              Browse Council Reg Certificate
              <VisuallyHiddenInput type="file" />
            </Button> */}

              <div
                style={{
                  border: "2px solid blue ",
                  padding: "10px",
                  borderRadius: "12px",
                }}
              >
                Browse Council Reg Certificate
              </div>

              {/* <div
              style={{
                borderRadius: "12px",
                height: "100px",
                width: "220px",
                boxShadow: "10px",
                position: "relative",
                top: "40px",
              }}
            > */}

              {/* </div> */}
            </Box>
          ) : (
            <Card
              sx={{
                minWidth: 275,
                minHeight: 150,
                maxWidth: 275,
                maxHeight: 100,
                mt: 4,
                position: "relative",
                left: "25%",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  maxHeight: "50px",
                  // justifyContent: "space-between",
                  // gap: "10px",
                }}
              >
                <img
                  src={pdfIcon}
                  style={{
                    minHeight: "16%",
                    maxHeight: "16%",
                    minWidth: "10% ",
                    maxWidth: "10% ",
                  }}
                ></img>
                <p
                  style={{
                    marginTop: "-2px",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Licence
                </p>
              </CardContent>
              <CardContent>
                <img
                  src={closeBtn}
                  onClick={closeImage}
                  style={{
                    minHeight: "16%",
                    maxHeight: "16%",
                    minWidth: "10% ",
                    maxWidth: "10% ",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                ></img>
              </CardContent>
              {/* <CardContent sx={{ maxHeight: 10 }}> */}
              <div
                style={{
                  maxHeight: "60px",
                  overflow: "hidden",
                  marginTop: "-10px",
                  // padding: "5px",
                  borderRadius: "20px",
                  width: "100%",
                }}
              >
                {certificate ? (
                  <img
                    src={certificate}
                    style={{
                      minHeight: "6%",
                      maxHeight: "6%",
                      // minWidth: "80% ",
                      // maxWidth: "80% ",
                      borderRadius: "10px",
                      border: "2px solid black",
                      marginTop: "-40px",
                      boxSizing: "content-box",
                      overflow: "hidden",
                    }}
                  ></img>
                ) : (
                  ""
                )}
                {/* </CardContent> */}
              </div>
            </Card>
          )}
        </DialogContent>
        <DialogActions
          sx={{ padding: 0, display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "12px",
              backgroundColor: "#0079BF !important",
              color: "#FFFFFF",
            }}
            // onClick={handleSubmitModal}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddClinicDialog;
