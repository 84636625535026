import React from "react";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { List, ListItem, ListItemText, Box } from "@mui/material";
import { Link } from "react-router-dom";
const RefundPolicy = () => {
  return (
    <Box
      sx={{
        padding: { xs: "20px", sm: "30px", md: "20px 50px", lg: "30px 80px" },
      }}
    >
      <div style={{ display: "flex " }}>
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/">
            {" "}
            <ArrowBackIcon />
          </Link>
        </div>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", padding: "10px 0px", marginLeft: "10px" }}
        >
          {" "}
          Refund Policy
        </Typography>
      </div>
      <List component="ol">
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  1. Refund Eligibility:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Go Denty does not generally provide refunds. Refunds will only
                  be issued in the event that a clinic is unable to provide
                  space on the selected date or on a mutually agreed future date
                  for a BDS or MDS doctor.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {" "}
                  2. Convenience Charges:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  In the event that a refund is issued, Go Denty reserves the
                  right to levy convenience charges of up to 2% on the refund
                  amount.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  3. Cancellation by User:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  If a clinic is ready to provide space, but the user wishes to
                  cancel the booking, Go Denty will not provide any refund in
                  such circumstances or in any other event.
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
      <Typography variant="h5" sx={{ fontWeight: "bold", padding: "10px 0px" }}>
        {" "}
        How to Claim a Refund :
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "#767980", fontWeight: 600, fontSize: "17px" }}
      >
        To claim a refund, please follow these steps:{" "}
      </Typography>

      <List component="ul">
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Write an email to our customer service team stating your
                  refund request.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Provide all the necessary details in the email, such as your
                  order number, transaction details, and the reason for the
                  refund.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Once wereceive your refund request, our team will review it
                  within 72 hours.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  If your refund request is approved, we will send you a
                  confirmation email and process the refund within 7 days.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Please note that the refund processing time may vary depending
                  on your payment method and the success of the transaction.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  If you have not received the refund within the specified
                  timeframe, or if you have any further questions, feel free to
                  reach out to our customer service team for assistance.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  By using the Go Denty mobile app and wesbite, you acknowledge
                  that you have read, understood, and agree to be bound by this
                  Refund Policy. If you do not agree to this policy, please do
                  not use the Go Denty app.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default RefundPolicy;
