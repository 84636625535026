import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import phone from "../../Assets/phone.png";
import flag from "../../Assets/greenFlag.png";
import email from "../../Assets/email.png";
import logout from "../../Assets/logout.png";
import edit from "../../Assets/edit.png";
import doc from "../../Assets/doc.png";
import CircularProgress from "@mui/material/CircularProgress";
import { getToken, removeLocalStorageItems } from "../../helper/authHelper";
import { useNavigate } from "react-router-dom";
import api from "../../api/index";
import EditMdsprofile from "./modals/EditMdsprofile";
import editicon from "../../Assets/editicon.png";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import PersonIcon from "@mui/icons-material/Person";

function ProfileInformation() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    stateCouncilNo: "",
    email: "",
    phoneNumber: "",
    name: "",
    imageUrl: "",
  });
  const [editProfileData, setEditProfiledata] = useState(false);

  const [loading, setLoading] = useState(false);
  const [editClinicModal, setEditClinicModal] = React.useState(false);

  const handleEditClinicOpen = () => {
    setEditClinicModal(true);
  };
  const handleEditClose = () => {
    setEditClinicModal(false);
  };

  const handleLogout = () => {
    removeLocalStorageItems();
    navigate("/");
  };

  const uploadImg = async (e) => {
    let fileName = e.target.files[0].name;
    let file = e.target.files[0];

    let awsSUrl = `https://godenty-docs.s3.ap-south-2.amazonaws.com/docs/${fileName}`;

    let response = await fetch(awsSUrl, {
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      setProfileData({ ...profileData, imageUrl: response?.url });

      let payload = { ...profileData, imageUrl: response?.url };

      // let authToken = getToken();
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: authToken,
      // };
      const { data } = await api.updateClinicData(payload);

      if (!data?.success) {
        return;
      }
    }
  };

  useEffect(() => {
    const getUserDetail = async () => {
      setLoading(true);

      try {
        let res = await api.getUserDetail();

        if (res?.data?.success) {
          let { email, phoneNumber, name, stateCouncilNo, imageUrl } =
            res.data.data.user;
          setProfileData({
            email,
            phoneNumber,
            name,
            stateCouncilNo,
            imageUrl,
          });
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    getUserDetail();
  }, [editProfileData]);

  return (
    <Card
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        borderRadius: 8,
        position: "relative",
        border: "3px solid #f6f6f6",
        boxShadow: "none",
        fontFamily: "manrope",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Button
            sx={{
              color: "#BEBEBE",
              fontWeight: 500,
              fontSize: "13px",
              position: "absolute",
              right: "3%",
              top: "2%",
              width: 69,
              padding: "5px 10px",
              borderRadius: "10px",
              textTransform: "none",
              border: "2px solid #e4e4e4",
              marginBottom:"50px"
            }}
            startIcon={<img src={edit} style={{ height: "18px" }} />}
            onClick={handleEditClinicOpen}
          >
            Edit
          </Button>
          {profileData?.imageUrl ? (
            <img
              src={profileData?.imageUrl}
              style={{ height: "170px", marginTop:"30px"}}
              alt="profile"
            />
          ) : (
            <PersonIcon sx={{ height: "10rem", width: "100%" }} />
          )}

          <Box>
            <label id="avatar-inp-label" htmlFor="avatar-inp">
              <img
                src={editicon}
                style={{
                  width: 34,
                  height: 34,
                }}
              />
            </label>
            <TextField
              sx={{
                display: "none",
              }}
              id="avatar-inp"
              type="file"
              inputProps={{ accept: "image/jpeg, image/png, image/jpg" }}
              onChange={uploadImg}
            />
          </Box>
          <Box
            sx={{ mb: 1 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              sx={{
                marginLeft: "1rem",
                fontWeight: 500,
                fontSize: "24px",
                color: "#182E4B",
              }}
            >
              {profileData?.name}
            </Typography>
            <Typography
              sx={{
                marginLeft: "1rem",
                fontWeight: 500,
                fontSize: "18px",
                color: "#9E9E9E",
              }}
            >
              MDS Graduate
            </Typography>
            <Typography
              sx={{
                marginLeft: "1rem",
                fontWeight: 500,
                fontSize: "18px",
                color: "#9E9E9E",
              }}
            >
              SCR No: {profileData?.stateCouncilNo}
            </Typography>
          </Box>
          <Divider />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <img src={phone} style={{ width: "24px", height: "24px" }} />
              <Typography
                sx={{
                  mx: 1,
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#9E9E9E",
                }}
              >
                {profileData?.phoneNumber}
              </Typography>
              <img src={flag} style={{ height: "20px" }} />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <img src={email} style={{ width: "24px", height: "24px" }} />
              <Typography
                sx={{
                  mx: 1,
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#9E9E9E",
                }}
              >
                {profileData.email}
              </Typography>
              <img src={flag} style={{ height: "20px" }} />
            </Box>
          </Box>
          <Divider />
          <Button
            onClick={handleLogout}
            sx={{
              color: "#182E4B",
              fontWeight: 500,
              fontSize: "18px",
              textTransform: "none",
            }}
            startIcon={
              <img src={logout} style={{ width: "24px", height: "24px" }} />
            }
          >
            Logout
          </Button>
          <EditMdsprofile
            open={editClinicModal}
            setOpen={handleEditClinicOpen}
            handleClose={handleEditClose}
            setEditProfiledata={setEditProfiledata}
          />
        </>
      )}
    </Card>
  );
}

export default ProfileInformation;
