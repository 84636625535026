import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import api from "../api/index";
import goDentyLogo from "../Assets/godentyImage.png";
import sent from "../Assets/sent.png";
import { singUpschema } from "./validations/signupSchema";

const GodentySignUpPage = () => {
  const navigate = useNavigate();
  const userTypes = ["Clinician", "MDS", "BDS"];
  const [selectedUserType, setSelectedUserType] = useState(userTypes[0]);
  const [stateDentalCouncil, setStateDentalCouncil] = useState("");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [inputErrors, setInputErrors] = useState({
    user_name: "",
    user_email: "",
    user_number: "",
    stateDentalCouncil: "",
    council_regnumber: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const stateDentalCouncilList = [
    { id: 1, state: "Andhra Pradesh" },
    { id: 2, state: "Arunachal Pradesh" },
    { id: 3, state: "Assam" },
    { id: 4, state: "Bihar" },
    { id: 5, state: "Chhattisgarh" },
    { id: 6, state: "Goa" },
    { id: 7, state: "Gujarat" },
    { id: 8, state: "Haryana" },
    { id: 9, state: "Himachal Pradesh" },
    { id: 10, state: "Jharkhand" },
    { id: 11, state: "Karnataka" },
    { id: 12, state: "Kerala" },
    { id: 13, state: "Madhya Pradesh" },
    { id: 14, state: "Maharashtra" },
    { id: 15, state: "Manipur" },
    { id: 16, state: "Meghalaya" },
    { id: 17, state: "Mizoram" },
    { id: 18, state: "Nagaland" },
    { id: 19, state: "Odisha" },
    { id: 20, state: "Punjab" },
    { id: 21, state: "Rajasthan" },
    { id: 22, state: "Sikkim" },
    { id: 23, state: "Tamil Nadu" },
    { id: 24, state: "Telangana" },
    { id: 25, state: "Tripura" },
    { id: 26, state: "Uttar Pradesh" },
    { id: 27, state: "Uttarakhand" },
    { id: 28, state: "West Bengal" },
    { id: 29, state: "Andaman and Nicobar Islands" },
    { id: 30, state: "Chandigarh" },
    { id: 31, state: "Dadra and Nagar Haveli and Daman and Diu" },
    { id: 32, state: "Lakshadweep" },
    { id: 33, state: "Delhi" },
    { id: 34, state: "Puducherry" },
  ];
  const [submitModal, setSubmitModal] = useState(false);

  const addFormData = (e) => {
    let { name, value } = e.target;

    if (name === "user_name") {
      value = value.slice(0, 40);
    }

    if (name === "user_email") {
      value = value.slice(0, 40);
    }
    if (name === "userType") {
      setSelectedUserType(value);
    }
    if (name === "stateDentalCouncil") {
      setStateDentalCouncil(value);
    }

    if (name === "user_number") {
      value = value.slice(0, 10);
    }

    const updatedFormData = { ...formData, [name]: value };

    try {
      singUpschema.parse(updatedFormData);
      setFormErrors({});
    } catch (error) {
      if (error.formErrors) {
        setFormErrors(error.formErrors.fieldErrors);
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      singUpschema.parse(formData);
    } catch (error) {
      if (error.formErrors) {
        setFormErrors(error.formErrors.fieldErrors);
      }
      return;
    }

    if (!acceptTerms) {
      setInputErrors({
        acceptTerms: acceptTerms
          ? ""
          : "Please accept the terms and conditions",
      });
      return;
    }
    SignUp();
  };
  const handleCloseSubmit = () => {
    setSubmitModal(false);
    navigate("/");
  };
  const SignUp = async () => {
    if (!formData.userType) {
      formData.userType = userTypes[0];
    }
    const params = {
      name: formData.user_name,
      email: formData.user_email,
      phoneNumber: +formData.user_number,
      role: formData.userType,
      password: formData.password,
      state: formData.stateDentalCouncil,
      stateCouncilNo: formData.council_regnumber,
    };

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    try {
      setLoading(true);
      let response = await api.userSignUp(params);
      setLoading(false);
      if (response?.data?.success === true) {
        // setFormData({});
        // setSelectedUserType(userTypes[0]);
        // setStateDentalCouncil("");
        // setAcceptTerms(false);
        // setSubmitModal(true);
        // navigate("/login");
        localStorage.setItem("auth", JSON.stringify(response.data.data.token));
        let userRole = response.data.data?.userData._role;
        localStorage.setItem("role", userRole);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.data?.userData)
        );

        //redirect to otp verification
        if (response?.data?.data?.userData?.isVerified == 0) {
          navigate("/verify-otp");
          return;
        }

        if (response?.data?.data?.userData?.firstLogin == 1 && userRole === 3) {
          if (response?.data?.data?.userData?.designation == "BDS") {
            navigate("/bds/profile");
          } else {
            navigate("/mds/profile");
          }
        } else if (
          response?.data?.data?.userData?.firstLogin == 1 &&
          userRole === 2
        ) {
          navigate("/clinic/profile");
        } else if (
          response?.data.data?.userData?.firstLogin == 0 &&
          response?.data.data?.userData?.approvalStatus == "APPROVED"
        ) {
          let userRole = localStorage.getItem("role");
          let authToken = localStorage.getItem("auth");
          let designation = response?.data?.data?.userData?.designation || "";
          localStorage.clear();
          window.location.href = `https://admin.godenty.com?role=${userRole}&auth=${authToken}&designation=${designation}`;
        }
        // else {
        //   //if it is not first logged in and it is not approved then we notify user about the review profile inforamtion
        //   handleClick();
        // }
        // setFormData({});
      }
    } catch (error) {
      setLoading(false);
    }
  };

  let passwordValidationPoints = [
    "Password should contain at least 8 characters, one number (0-9), one lowercase letter (a-z) ,one uppercase letter (A-Z),one special character (e.g., !@#$%^&*)",
  ];

  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
      <div className="SignUpPage-leftSidecontainer">
        <Link to="/" style={{ textAlign: "center" }}>
          <img src={goDentyLogo} alt="goDentyLogo" style={{ width: "35%" }} />
        </Link>

        <h3 style={{ color: "white", marginTop: "auto" }}>
          Unlock dental career possibilities with us – where hiring meets
          convenience.
        </h3>
      </div>
      <Dialog
        onClose={handleCloseSubmit}
        aria-labelledby="customized-dialog-title"
        open={submitModal}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            p: 2,
            borderRadius: "40px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseSubmit}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "20px",
          }}
        >
          <img
            src={sent}
            alt="Sent"
            style={{ width: "100px", height: "100px" }}
          />

          <Typography
            align="center"
            sx={{
              marginTop: "10px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            Thank you for joining us!
          </Typography>
          <Typography
            align="center"
            sx={{
              marginTop: "10px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            Your profile is under review. We will get back to you soon.
          </Typography>
        </DialogContent>
      </Dialog>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        <form onSubmit={handleSignUp} className="signUp-formcontainer">
          <h4>👋 Welcome to Godenty</h4>
          <p style={{ color: "rgba(190, 190, 190, 1)", fontSize: 13 }}>
            Create account by filling the below details
          </p>
          <div style={{ marginBottom: "15px", minHeight: "68px" }}>
            <p style={{ marginBottom: "1px" }}>Name</p>
            <input
              className="signUp-formcontainer-input"
              type="name"
              name="user_name"
              onChange={(e) => {
                if (formData?.user_name?.length > 40) return;
                addFormData(e);
              }}
              value={formData.user_name || ""}
              placeholder="Enter your Name "
              style={{ marginBottom: "0px" }}
            />

            {formErrors.user_name && (
              <span className="errorText">{formErrors.user_name[0]}</span>
            )}
          </div>
          <div style={{ marginBottom: "15px", minHeight: "68px" }}>
            <p style={{ marginBottom: "1px" }}>Email ID</p>
            <input
              className="signUp-formcontainer-input"
              type="mail"
              name="user_email"
              onChange={addFormData}
              value={formData.user_email || ""}
              placeholder="Enter Your Email ID  "
              style={{ marginBottom: "0px" }}
            />

            {formErrors.user_email && (
              <span className="errorText">{formErrors.user_email[0]}</span>
            )}
          </div>
          <div style={{ marginBottom: "15px", minHeight: "68px" }}>
            <p style={{ marginBottom: "1px" }}>Contact Number</p>
            <input
              className="signUp-formcontainer-input"
              type="text"
              name="user_number"
              onChange={addFormData}
              value={formData.user_number || ""}
              placeholder="Enter your Contact Number "
              style={{ marginBottom: "0px" }}
              onInput={(e) => {
                const target = e?.target;
                target.value = e?.target?.value.replace(/[^0-9]/g, "");
              }}
            />

            {formErrors.user_number && (
              <span className="errorText">{formErrors.user_number[0]}</span>
            )}
          </div>

          <p style={{ marginBottom: "1px" }}>Designation</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {userTypes.map((type) => (
              <div
                key={type}
                style={{
                  width: "30%",
                  boxShadow: "0px 4px 80px 0px rgba(0, 0, 0, 0.06)",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <div>
                  <input
                    type="radio"
                    id={type.toLowerCase()}
                    name="userType"
                    value={type}
                    checked={selectedUserType === type}
                    onChange={addFormData}
                    style={{ marginBottom: "0px" }}
                  />
                  <label
                    className="label-designation"
                    htmlFor={type.toLowerCase()}
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    {type}
                  </label>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              marginBottom: "15px",
            }}
          >
            <select
              id="state"
              value={stateDentalCouncil}
              name="stateDentalCouncil"
              onChange={addFormData}
              className="signUp-formcontainer-input"
              style={{
                marginTop: "15px",
                cursor: "pointer",
                marginBottom: "0px",
              }}
            >
              <option value="" style={{ color: "rgba(165, 165, 165, 1)" }}>
                --Select State Dental Council--
              </option>
              {stateDentalCouncilList.map((state) => (
                <option key={state.id} value={state.state}>
                  {state.state}
                </option>
              ))}
            </select>

            {formErrors.stateDentalCouncil && (
              <span className="errorText">
                {formErrors.stateDentalCouncil[0]}
              </span>
            )}
          </div>
          <div style={{ marginBottom: "15px", minHeight: "68px" }}>
            <p style={{ marginBottom: "1px" }}>
              State council registration No.
            </p>
            <input
              type="text"
              name="council_regnumber"
              onChange={addFormData}
              value={formData.council_regnumber || ""}
              className="signUp-formcontainer-input"
              placeholder="Enter State Council Registration No"
              style={{ marginBottom: "0px" }}
            />

            {formErrors.council_regnumber && (
              <span className="errorText">
                {formErrors.council_regnumber[0]}
              </span>
            )}
          </div>

          <div style={{ marginBottom: "15px", minHeight: "68px" }}>
            <p style={{ marginBottom: "1px" }}>Enter Password</p>
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={addFormData}
                value={formData.password || ""}
                className="signUp-formcontainer-input"
                placeholder="Enter Password"
                style={{ marginBottom: "0px" }}
              />
              {/* working */}
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translate(0, -50%)",
                  right: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </div>
            </div>

            {formErrors.password && (
              <span className="errorText">{formErrors.password[0]}</span>
            )}
          </div>
          <div style={{ marginBottom: "15px", minHeight: "68px" }}>
            <p style={{ marginBottom: "1px" }}>Confirm Password</p>
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                type={showCPassword ? "text" : "password"}
                name="confirmPassword"
                onChange={addFormData}
                value={formData.confirmPassword || ""}
                className="signUp-formcontainer-input"
                placeholder="Enter Confirm Password"
                style={{ marginBottom: "0px" }}
              />

              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translate(0, -50%)",
                  right: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setShowCPassword(!showCPassword)}
              >
                {showCPassword ? <VisibilityOff /> : <Visibility />}
              </div>
            </div>

            <ul>
              {passwordValidationPoints?.map((elem, index) => {
                return (
                  <li
                    style={{
                      fontSize: "10px",
                      color: "#A9A9A9",
                      listStyle: "none",
                      padding: "4px",
                    }}
                    key={index}
                  >
                    {elem}
                  </li>
                );
              })}
            </ul>

            {formErrors.confirmPassword && (
              <span className="errorText">{formErrors.confirmPassword[0]}</span>
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginBottom: "15px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={acceptTerms}
                onChange={(e) => {
                  setAcceptTerms(!acceptTerms);
                  addFormData(e);
                }}
                style={{ height: "21px", width: "21px", cursor: "pointer" }}
              />
              <p
                style={{
                  display: "inline-block",
                  marginLeft: "8px",
                  fontSize: 13,
                }}
              >
                I accept the
                <Link to="/termsAndConditions">
                  <span style={{ color: "rgba(0, 121, 191, 1)" }}>
                    &nbsp;terms and conditions{" "}
                  </span>
                </Link>
              </p>
            </div>
            {inputErrors.acceptTerms && !acceptTerms && (
              <span className="errorText">{inputErrors.acceptTerms}</span>
            )}
          </div>

          <button
            type="submit"
            style={{
              width: "100%",
              padding: "1rem",
              borderRadius: "12px",
              cursor: "pointer",
              border: "none",
              backgroundColor: "rgba(0, 121, 191, 1)",
              color: "white",
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              "Sign Up"
            )}
          </button>

          <p
            style={{
              color: "rgba(152, 152, 152, 1)",
              textAlign: "center",
              fontSize: "13px",
              marginTop: 6,
            }}
          >
            Already have an account?{" "}
            <Link to="/login">
              <span
                style={{
                  color: "rgba(55, 159, 255, 1)",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
              >
                Login
              </span>
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};
export default GodentySignUpPage;
