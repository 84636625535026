import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import api from "../../../api/index";
import closeBtn from "../../../Assets/closeBtn.svg";
import cloudAdd from "../../../Assets/cloudAdd.png";
import pdfIcon from "../../../Assets/pdf.svg";
import { getToken } from "../../../helper/authHelper";
import capitalise from "../../../helper/capitalise";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ProfessionalModal = ({
  open,
  handleClose,
  setOpen,
  handleClickOpen,
  handleCloseSubmit,
  handleSubmitModal,
  choices,
  onSelectedChange,
  addModal,
  setProfessionalModalError,
  setProfessionalInformationUpdated,
  setSelectedProfessionArray,
  selectedProfessionArray,
  formDataDetails,
}) => {
  const [certificate, setCertificate] = useState("");

  const removeItem = (profession) => {
    setSelectedProfessionArray((prev) => {
      let filteredArray = prev.filter((el) => {
        return el !== profession;
      });
      return filteredArray;
    });
  };

  console.log(certificate);
  const closeImage = () => {
    console.log("called");
    setCertificate("");
  };

  const [selected, setSelected] = useState(null);

  const handleChoicesClick = (selectedChoices) => {
    setSelected(selectedChoices);

    setSelectedProfessionArray((prev) => {
      let set = new Set(prev);
      if (!set.has(selectedChoices)) {
        return [...prev, selectedChoices];
      } else {
        let filteredArray = prev.filter((el) => {
          return el !== selectedChoices;
        });
        return filteredArray;
      }
    });
  };

  const handleSave = async () => {
    //console.log(selected);
    // handleSubmitModal(selected);

    let professionalChoicesArray = [...selectedProfessionArray];

    if (!professionalChoicesArray || professionalChoicesArray.length === 0)
      return;

    for (let el of professionalChoicesArray) {
      if (el === "Part time") {
        let index = professionalChoicesArray.indexOf(el);
        professionalChoicesArray[index] = "Part-time";
      } else if (el === "Full time") {
        let index = professionalChoicesArray.indexOf(el);
        professionalChoicesArray[index] = "Full-time";
      }
    }

    const body = { workType: professionalChoicesArray };
    // console.log(body);
    // const headers = {
    //   Authorization: getToken(),
    //   "Content-Type": "application/json",
    // };

    try {
      let res = await api.updateClinicData(body);
      console.log(res.data);
      if (res?.data.success) {
        setProfessionalModalError(null);
        setProfessionalInformationUpdated(true);
        handleClose();
        window.location.reload();
      } else if (!res?.data.success) {
        console.log("failed");
        setProfessionalModalError(res.data.message);
      }
    } catch (e) {
      setProfessionalModalError(e.response.data.message);
      console.log(e.response.data.message);
    }
  };

  const handleCancel = () => {
    handleClose();
    if (!formDataDetails.userDetails?.workType) {
      setSelectedProfessionArray([]);
    }
    if (formDataDetails.userDetails?.workType) {
      let arr = formDataDetails.userDetails.workType.split(",");
      arr = arr.map((el) => capitalise(el));
      setSelectedProfessionArray(arr);
    }
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            borderRadius: "40px",
            backgroundColor: "#fcfcfc",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontWeight: 600,
            fontSize: "26px",
            color: "#182E4B",
          }}
          id="customized-dialog-title"
        >
          {!addModal ? "Edit" : "Add"} - Professional choices
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            variant="h6"
            style={{ marginBottom: "12px", color: "#000", fontSize: "15px" }}
          >
            Professional choices
          </Typography>
          {
            <div>
              {(!selectedProfessionArray ||
                selectedProfessionArray.length === 0) && (
                <p style={{ color: "red" }}>
                  please Select one Professional Choice
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "0.2rem",
                }}
              >
                {selectedProfessionArray &&
                  selectedProfessionArray.length > 0 &&
                  selectedProfessionArray?.map((profession) => {
                    return (
                      <Typography
                        key={profession}
                        onClick={() => removeItem(profession)}
                        sx={{
                          padding: "4px 8px",
                          border: "1px solid gray",
                          borderRadius: "20px",
                          color: "black",
                          fontSize: "12px",
                          background: "#80bcdf",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {profession}
                        <span
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                        >
                          {" "}
                          <CloseIcon sx={{ height: "16px" }} />
                        </span>
                      </Typography>
                    );
                  })}
              </div>
            </div>
          }

          <Typography
            variant="h6"
            style={{ margin: "12px 0px", color: "#000", fontSize: "15px" }}
          >
            Select Below to Add Professional Choices
          </Typography>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {choices.map((choice, index) => (
              <Button
                key={index}
                sx={{
                  padding: "4px 8px",
                  border: "1px solid gray",
                  borderRadius: "20px",
                  color: "black",
                  fontSize: "12px",
                }}
                onClick={() => handleChoicesClick(choice)}
              >
                {choice}
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  {" "}
                  <AddIcon sx={{ height: "16px" }} />
                </span>
              </Button>
            ))}
          </div>
        </DialogContent>
        <DialogActions
          sx={{ padding: 0, display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "12px",
              backgroundColor: "#0079BF !important",
              color: "#FFFFFF",
            }}
            onClick={handleSave}
          >
            {!addModal ? "Add" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfessionalModal;
