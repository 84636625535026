import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../api/index";
import { emailValidation } from "../validations/validations";
import NewpasswordComponent from "./Newpasswordcreatecomponent";

const ForgotPasswordComponent = ({ setForgetPasswordComponent }) => {
  const [forgetPassowrd, setForgetpassword] = useState("");
  const [showOtpEnter, setShowOtpEnter] = useState(false);
  const [getOtpError, setGetOtpError] = useState("");
  const [showNewPasswordComponent, setShowNewPasswordComponent] =
    useState(false);
  const [enterOtpError, setEnterOtpError] = useState(null);

  const getOtpClick = async () => {
    setEnterOtpError(null);
    if (!forgetPassowrd) {
      setGetOtpError("Please enter email address");
      return;
    }
    const emailValidationResp = await emailValidation(forgetPassowrd);
    if (!emailValidationResp) {
      setGetOtpError("invalid email");
      return;
    }
    try {
      const res = await api.sendOtp({ email: forgetPassowrd });
      if (res?.data?.success) {
        setShowOtpEnter(true);
      } 
    } catch (error) {
      // toast.error(error.response?.data?.message);
    }
  };
  const OtpenterComponent = ({ email }) => {
    const navigate = useNavigate();
    const [otpEnter, setOtpEnter] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const OtpClick = async () => {
      let body = { email: email, otp: otpEnter, password };

      if (password === "" || confirmPassword === "" || otpEnter === "") {
        toast.error("Please fill the details to reset password");

        return;
      }
      if (password !== confirmPassword) {
        toast.error("Password and confirm password should match");
        return;
      }

      try {
        let res = await api.enterOtp(body);
        if (res?.data?.success) {
          toast("Password reset successful!");
          navigate("/");
          //setShowOtpEnter(false);
          //  setShowNewPasswordComponent(true);
        } 
      } catch (e) {
        if (e?.response?.data.success === false) {
          setEnterOtpError(e?.response?.data?.message);
        }
      }
      setOtpEnter("");
      setPassword("");
      setConfirmPassword("");
    };

    const resendOtp = async () => {
      setEnterOtpError(null);
      setShowOtpEnter(false);
      try {
        const res = await api.sendOtp({ email: forgetPassowrd });
        if (res?.data?.success) {
          setShowOtpEnter(true);
        }
      } catch (error) {
        // toast.error(error?.response?.data?.message);
      }
    };

    return (
      <div className="signUp-formcontainer" style={{ overflow: "auto" }}>
        <h4>👋 Welcome to, Godenty </h4>
        <p style={{ color: "rgba(190, 190, 190, 1)" }}>
          We Have sent the OTP to{" "}
          <span style={{ color: "black" }}>{forgetPassowrd}</span>
        </p>
        <input
          style={{ marginTop: "15px" }}
          className="signUp-formcontainer-input"
          type="text"
          value={otpEnter}
          onChange={(e) => {
            setOtpEnter(e.target.value);
          }}
          placeholder="Enter OTP "
          onInput={(e) => {
            const target = e?.target;
            target.value = e?.target?.value.replace(/[^0-9]/g, "");
          }}
        />
        <input
          style={{ marginTop: "15px" }}
          className="signUp-formcontainer-input"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Enter new Password "
          // onInput={(e) => {
          //   const target = e?.target;
          //   target.value = e?.target?.value.replace(/[^0-9]/g, "");
          // }}
        />
        <input
          style={{ marginTop: "15px" }}
          className="signUp-formcontainer-input"
          type="password"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          placeholder="Reenter new Password "
          // onInput={(e) => {
          //   const target = e?.target;
          //   target.value = e?.target?.value.replace(/[^0-9]/g, "");
          // }}
        />
        <span style={{ fontWeight: "500" }}>
          Did not receive OTP ?,{" "}
          <span
            onClick={resendOtp}
            style={{ color: "rgba(55, 159, 255, 1)", cursor: "pointer" }}
          >
            Resend
          </span>
        </span>

        <button
          style={{
            width: "100%",
            padding: "1rem",
            borderRadius: "12px",
            cursor: "pointer",
            border: "none",
            backgroundColor: "rgba(0, 121, 191, 1)",
            color: "white",
            marginTop: "15px",
            marginBottom: "15px",
            fontWeight: "bold",
          }}
          onClick={OtpClick}
        >
          Verify
        </button>
        {enterOtpError && <span className="errorText">{enterOtpError}</span>}

        <p
          style={{
            color: "rgba(152, 152, 152, 1)",
            textAlign: "center",
            fontWeight: "500",
            fontSize: "18px",
          }}
        >
          Already have an account ,
          <span
            onClick={() => setForgetPasswordComponent(false)}
            style={{ color: "rgba(55, 159, 255, 1)", cursor: "pointer" }}
          >
            Login
          </span>
        </p>
      </div>
    );
  };

  return (
    <>
      {!showOtpEnter && !showNewPasswordComponent && (
        <div className="signUp-formcontainer">
          <h4>👋 Welcome to, Godenty </h4>
          <p style={{ color: "rgba(190, 190, 190, 1)" }}>Forgot Password</p>
          <input
            style={{ marginTop: "15px", marginBottom: "0px" }}
            className="signUp-formcontainer-input"
            type="text"
            value={forgetPassowrd}
            onChange={(e) => {
              setForgetpassword(e.target.value);
              setGetOtpError("");
            }}
            placeholder="Enter Registered Email Address  "
          />
          {getOtpError && <span className="errorText">{getOtpError}</span>}
          <button
            style={{
              width: "100%",
              padding: "1rem",
              borderRadius: "12px",
              cursor: "pointer",
              border: "none",
              backgroundColor: "rgba(0, 121, 191, 1)",
              color: "white",
              marginTop: "15px",
              marginBottom: "15px",
              fontWeight: "bold",
            }}
            onClick={getOtpClick}
          >
            Get OTP
          </button>

          <p style={{ color: "rgba(190, 190, 190, 1)", textAlign: "center", fontSize: "13px" }}>
            Don’t have an account?
            <Link to="/signup">
              <span
                style={{ color: "rgba(55, 159, 255, 1)", cursor: "pointer" }}
              >
                &nbsp;Sign up
              </span>
            </Link>
          </p>
        </div>
      )}
      {showNewPasswordComponent && (
        <NewpasswordComponent email={forgetPassowrd} />
      )}
      {showOtpEnter ? <OtpenterComponent email={forgetPassowrd} /> : null}
    </>
  );
};
export default ForgotPasswordComponent;
