// import MiddlesectionCardsLeftBg from "../Assets/MiddlesectionCardsLeftBg.png";
import rectaAngleImage from "../Assets/Rectangle.png";
import rectaAngleImage2 from "../Assets/RectangleImage2 (2).png";
import rectAngleImage3 from "../Assets/Rectangle3.png";
import clinicianLastcontainerImage from "../Assets/clinicianlastcontainerimage.png";
// import c1 from "../Assets"
// import c2 from "../Assets"

const ClinicianComponent = ({ currentActiveTab }) => {
  const opacityClass = currentActiveTab === 1 ? "opacity" : "no-opacity";

  return (
    <div
      //  className={`middle-section-background middle-section-background-desktop ${opacityClass}`}
      className={`middle-section-background-desktop ${opacityClass}`}
    >
      <div className="middle-section-cards first-middle-section-card position-relative card-height">
        <div style={{ padding: "7%" }}>
          <h3>
            Simplify your team
            <br /> building
          </h3>
          <p>
            You can finally bid goodbye to staffing challenges!  With GoDenty,
            you can connect with both full-time BDS and MDS doctors
            effortlessly. 
          </p>
        </div>
        <div className="small-circle">1</div>
        <img src={rectaAngleImage} alt="rectangle" className="image-contain" />
        {/* <img src={newFirstimage} alt="newFirstimage"/> */}
      </div>
      <div className="middle-section-cards middle-section-cards-second position-relative card-height">
        <div style={{ padding: "5%" }}>
          <h3>
            Buy or Sell Your clinic
            <br /> efficiently
          </h3>
          <p>
            Go Denty streamlines the process of buying or selling your clinic,
            ensuring a smooth transition for all dentists involved. Connect with
            potential buyers or sellers, enabling an efficient and smooth
            practice transition. 
          </p>
        </div>
        <div className="small-circle">2</div>
        <img
          src={rectaAngleImage2}
          alt="rectaAngleImage2"
          className="image-contain image-contain-mobile"
        />
      </div>
      <div className="middle-section-cards clinician-lastcard display-smallscreen-middle position-relative card-height">
        <div style={{ padding: "5%" }}>
          <h3>
            Offer your
            <br /> clinic space for Rent
          </h3>
          <p>
            Make use of the spare or less frequently used space by renting it to
            professionals looking for them. A perfect solution that can bring
            dentists within the community together, help each other out,
            strengthen and contribute to the advancement of dentist healthcare. 
          </p>
        </div>
        <div className="small-circle ">3</div>
        <img
          src={rectAngleImage3}
          alt="rectaAngleImage3"
          className="image-contain"
        />
      </div>
      <div className="clinicianLastcontainerImage position-relative card-height">
        <div className="small-circle noDisplay-bigScreen">2</div>
        <img
          style={{ objectFit: "contain", width: "100%", margin: "auto" }}
          src={clinicianLastcontainerImage}
          alt="clinicianLastcontainerImage"
        />
      </div>
    </div>
  );
};
export default ClinicianComponent;
