import React from "react";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { List, ListItem, ListItemText, Box } from "@mui/material";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        padding: { xs: "20px", sm: "30px", md: "20px 50px", lg: "30px 80px" },
      }}
    >
      <div style={{ display: "flex " }}>
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/">
            {" "}
            <ArrowBackIcon />
          </Link>
        </div>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", padding: "10px 0px", marginLeft: "10px" }}
        >
          {" "}
          Privacy Policy
        </Typography>
      </div>
      <Typography
        variant="body1"
        sx={{ color: "#767980", fontWeight: 600, fontSize: "17px" }}
      >
        At Godenty, we are committed to safeguarding the privacy and
        confidentiality of our users' information. Our Privacy Policy is
        designed to ensure that your personal data is protected. Here are the
        key aspects of our Privacy Policy:
      </Typography>

      <List component="ul">
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Non-Disclosure:
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  We do not disclose any user information to third parties
                  unless required by law or authorized by the user.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Information Sharing:
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  We may share limited information, such as a random ID number,
                  with thirdparty service providers for the purpose of user
                  identification. However, we do not share personal data such as
                  email addresses or names with these providers.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Obligation to Provide Information:
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  In certain circumstances, if requested by any legal authority,
                  wemaybeobligated to provide information. However, we will only
                  disclose the minimum amount of information required by law.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Disclosure to Selected Clinic:
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  We may share your registered number with the clinic you have
                  selected. This is done to facilitate communication and enhance
                  your experience with the clinic.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Disclosure to BDS Doc:
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  We may disclose clinic details to BDS doc (Board of Dental
                  Surgery) to ensure compliance with regulatory requirements and
                  maintain high standards of dental care.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#767980", marginTop: "10px" }}
                >
                  While we take all necessary measures to protect your
                  information, it's important to note that no method of
                  transmission over the internet or electronic storage is 100%
                  secure. We encourage you to review our full Privacy Policy for
                  a comprehensive understanding of how we handle and protect
                  your data.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#767980", marginTop: "10px" }}
                >
                  Your privacy is of utmost importance to us, and we are
                  committed to maintaining transparency in our practices. If you
                  have any questions or concerns regarding our Privacy Policy,
                  please do not hesitate to contact our support team.
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default PrivacyPolicy;
