import mdsImage1 from "../Assets/Mdsimage1.png";
import mdsImage2 from "../Assets/MdsImage2.png";
import mdsImage3 from "../Assets/MdsImage3.png";
import mdsImage4 from "../Assets/MdsImage4.png";
// import b1 from "../Assets"
// import b2 from "../Assets"
// import b3 from "../Assets"
// import b4 from "../Assets"

const BdsGraduateComponent = ({ currentActiveTab }) => {
  const opacityClass = currentActiveTab === 3 ? "opacity" : "no-opacity";
  return (
    <div className={`middle-section-background-desktop ${opacityClass}`}>
      <div className="middle-section-cards first-middle-section-card position-relative card-height">
        <div style={{ padding: "6.4%" }}>
          <h3>
            Book Clinic Space
            <br /> With Ease
          </h3>
          <p>
            You can book clinic spaces for minor operations like scaling, root
            canal, etc. Choose the perfect space for your practice. We ensure
            that you are equipped with the facilities you need to build your
            career.  
          </p>
        </div>
        <div className="small-circle">1</div>
        <img src={mdsImage1} alt="rectangle" className="image-contain" />
      </div>
      <div className="middle-section-cards middle-section-cards-second position-relative card-height">
        <div style={{ padding: "7%" }}>
          <h3>
            Explore Full- Time <br />
            Opportunities
          </h3>
          <p>
            Go Denty offers quality full-time job opportunities. You can browse
            through the available positions posted by verified clinics and
            secure a quality job that aligns with your skills and interests,
            hassle-free.
          </p>
        </div>
        <div className="small-circle">2</div>
        <img src={mdsImage2} alt="rectaAngleImage2" className="image-contain" />
      </div>
      <div className="middle-section-cards position-relative card-height">
        <div style={{ padding: "5%" }}>
          <h3>
            Flexible Part-Time Job <br />
            Opportunities
          </h3>
          <p>
            We understand the significance of finding a part-time job that
            accommodates your study schedule for exams and allows you to delve
            further into the subject.  Go Denty helps you find the perfect job
            that harmonises with your goals and supports your career.  
          </p>
        </div>
        <div className="small-circle">3</div>
        <img src={mdsImage3} alt="mdsImage3" className="image-contain" />
      </div>
      <div className="middle-section-cards middle-section-cards-second position-relative card-height">
        <div style={{ padding: "6.9%" }}>
          <h3>Buy clinic efficiently</h3>
          <p>
            Go Denty streamlines the process of buying a clinic, ensuring a
            smooth transition for all dentists involved. Connect with potential
            buyers or sellers, enabling an efficient and smooth practice
            transition. 
          </p>
        </div>
        <div className="small-circle">4</div>
        <img src={mdsImage4} alt="mdsImage4" className="image-contain" />
      </div>
    </div>
  );
};
export default BdsGraduateComponent;
