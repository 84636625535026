import "./styles.css";
import goDentyImage from "../../Assets/godentyImage.png";
import userProfile from "../../Assets/userProfileLogo.svg";
import { Typography } from "@mui/material";
import navbarImageuploadicon from "../../Assets/navbarImageuploadicon.png";
import { Link } from "react-router-dom";
const BdsMdsHeader = () => {
  const nabrLinks = [
    { id: 1, link: "Clinician" },
    { id: 2, link: "MDS Graduate" },
    { id: 3, link: "BDS Graduate" },
  ];
  return (
    <div className="bdsmdsFirstLoginPageNavbar">
      <Link to="/">
        <img src={goDentyImage} alt="navbarimg" className="goLogImg" />
      </Link>
      <div className="conmmon-navbar-container">
        {!localStorage.getItem("auth") &&
          nabrLinks.map((navLink) => (
            <Link to="/">
              <Typography
                style={{
                  fontFamily: "Manrope",
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: "500",
                  fontSize: "18px",
                  whiteSpace: "nowrap",
                }}
                key={navLink.id}
              >
                {navLink.link}
              </Typography>
            </Link>
          ))}

        <img
          src={navbarImageuploadicon}
          alt="navbarImageuploadicon"
          style={{ height: "40px" }}
        />
      </div>
    </div>
  );
};
export default BdsMdsHeader;
