import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

import api from "../../../api/index";
import { getToken } from "../../../helper/authHelper";

const EditBdsProfile = ({ open, handleClose, setEditProfiledata }) => {
  const stateDentalCouncilList = [
    { id: 1, state: "Andhra Pradesh" },
    { id: 2, state: "Arunachal Pradesh" },
    { id: 3, state: "Assam" },
    { id: 4, state: "Bihar" },
    { id: 5, state: "Chhattisgarh" },
    { id: 6, state: "Goa" },
    { id: 7, state: "Gujarat" },
    { id: 8, state: "Haryana" },
    { id: 9, state: "Himachal Pradesh" },
    { id: 10, state: "Jharkhand" },
    { id: 11, state: "Karnataka" },
    { id: 12, state: "Kerala" },
    { id: 13, state: "Madhya Pradesh" },
    { id: 14, state: "Maharashtra" },
    { id: 15, state: "Manipur" },
    { id: 16, state: "Meghalaya" },
    { id: 17, state: "Mizoram" },
    { id: 18, state: "Nagaland" },
    { id: 19, state: "Odisha" },
    { id: 20, state: "Punjab" },
    { id: 21, state: "Rajasthan" },
    { id: 22, state: "Sikkim" },
    { id: 23, state: "Tamil Nadu" },
    { id: 24, state: "Telangana" },
    { id: 25, state: "Tripura" },
    { id: 26, state: "Uttar Pradesh" },
    { id: 27, state: "Uttarakhand" },
    { id: 28, state: "West Bengal" },
    { id: 29, state: "Andaman and Nicobar Islands" },
    { id: 30, state: "Chandigarh" },
    { id: 31, state: "Dadra and Nagar Haveli and Daman and Diu" },
    { id: 32, state: "Lakshadweep" },
    { id: 33, state: "Delhi" },
    { id: 34, state: "Puducherry" },
  ];
  const [profileData, setProfileData] = useState({
    stateCouncilNo: "",
    designation: "",
    state: "",
    email: "",
    phoneNumber: "",
    name: "",
  });

  useEffect(() => {
    const getUserDetail = async () => {
      // let authToken = getToken();
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: authToken,
      // };
      try {
        let res = await api.getUserDetail();
        if (res?.data?.success) {
          let { email, phoneNumber, name, stateCouncilNo, designation, state } =
            res.data.data.user;
          setProfileData({
            email,
            phoneNumber,
            name,
            stateCouncilNo,
            designation,
            state,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    getUserDetail();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      // let authToken = getToken();
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: authToken,
      // };
      // let res = await api.updateClinicData(profileData, headers);
      let res = await api.updateClinicData(profileData);
      //console.log(res);
      setEditProfiledata(true);
      handleClose();
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  //  console.log(profileData);
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            borderRadius: "40px",
            backgroundColor: "#fcfcfc",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontWeight: 600,
            fontSize: "26px",
            color: "#182E4B",
          }}
          id="customized-dialog-title"
        >
          Edit - Profile Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="name"
            value={profileData.name}
            onChange={handleChange}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <FormControl style={{ marginBottom: "10px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              Designation
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={
                profileData?.designation === null
                  ? "BDS"
                  : profileData?.designation
              }
              //   onChange={handleChange}
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              <FormControlLabel value="BDS" control={<Radio />} label="BDS" />
              {/* <FormControlLabel value="MDS" control={<Radio />} label="MDS" />
              <FormControlLabel value="BDS" control={<Radio />} label="BDS" /> */}
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "10px" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={profileData.state}
              onChange={handleChange}
              name="state"
            >
              {stateDentalCouncilList.map((state) => (
                <MenuItem value={state.state.toUpperCase()} key={state.id}>
                  {state.state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="StateCouncilNo"
            variant="outlined"
            name="stateCouncilNo"
            value={profileData.stateCouncilNo}
            onChange={handleChange}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Email"
            name="email"
            value={profileData.email}
            onChange={handleChange}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Number"
            name="phoneNumber"
            type="number"
            value={profileData.phoneNumber}
            onChange={handleChange}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <DialogActions
            sx={{ padding: 0, display: "flex", justifyContent: "flex-end" }}
          >
            <Button onClick={handleClose} style={{ fontWeight: "bold" }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              sx={{
                borderRadius: "12px",
                backgroundColor: "#0079BF !important",
                color: "#FFFFFF",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditBdsProfile;
