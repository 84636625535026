export let mobileNumberValidation = async (value) => {
    let re = /^[0-9]{10}$/;
  
    let res = re.test(value);
    return res;
  };
  
  export let emailValidation = async (value) => {
    let re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  
    let res = re.test(value);
    return res;
};
