import axios from "axios";
import { toast } from "react-toastify";

export const HttpService = axios.create({
  baseURL: "https://adminpanel.godenty.com",
  // baseURL: "http://localhost:4000",
  // baseURL: "https://5jj0vx51-4000.inc1.devtunnels.ms/",
});

HttpService.interceptors.request.use((config) => {
  let auth = localStorage.getItem("auth");
  if (auth) {
    auth = JSON.parse(auth);
    config.headers.Authorization = "Bearer " + auth?.accessToken;
  }
  return config;
});

HttpService.interceptors.response.use(
  function (response) {
    if (response.data.success) {
      if (response.data.message) {
        toast.success(response.data.message);
      }
    } else {
      toast.error(response?.data?.message || "Something went wrong.");
    }
    return response;
  },
  function (error) {
    toast.error(error?.response?.data?.message || "Something went wrong.", {
      toastId: error?.response?.data?.message || "intercept-toast-id",
    });
    if (error.response.status === 401) {
      //if received unauthorized status code then only logout the user
      localStorage.clear();
      window.location.reload();
      return Promise.reject(error);
    }
  }
);
// const refreshAuthLogic = async (failedRequest) => {
//   let authObj;
//   const authStr = localStorage.getItem("auth");
//   if (authStr) {
//     authObj =
//       typeof localStorage.getItem("auth") === "string"
//         ? JSON.parse(authStr)
//         : authObj;
//     return HttpService.post(`/api/v1/refresh-token`, {
//       refreshToken: authObj.refreshToken,
//     })
//       .then((response) => {
//         if (response.data.success) {
//           localStorage.setItem(
//             "auth",
//             JSON.stringify(response.data.data.tokens)
//           );
//           failedRequest.response.config.headers.Authorization =
//             "Bearer " + response.data.data.tokens.accessToken;
//           return Promise.resolve();
//         } else {
//           localStorage.removeItem("auth");
//           window.location.reload();
//           return Promise.reject();
//         }
//       })
//       .catch((e) => {
//         localStorage.removeItem("auth");
//         window.location.reload();
//         return Promise.reject();
//       });
//   } else {
//     localStorage.removeItem("auth");
//     return Promise.reject();
//   }
// };
// createAuthRefreshInterceptor(HttpService, refreshAuthLogic);
