export const getToken = () => {
  let authTokenStringified = localStorage.getItem("auth");
  let authTokenParsed = JSON.parse(authTokenStringified);
  let authToken = authTokenParsed?.accessToken;
  authToken = `Bearer ${authToken}`;
  return authToken;
};

export const removeLocalStorageItems = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("role");
  localStorage.removeItem("user");
};

export const redirectUser = (data, navigate) => {
  let userRole = data?.userData?._role || "";

  if (data?.userData?.firstLogin == 1 && userRole === 3) {
    if (data?.userData?.designation == "BDS") {
      navigate("/bds/profile");
    } else {
      navigate("/mds/profile");
    }
  } else if (data?.userData?.firstLogin == 1 && userRole === 2) {
    navigate("/clinic/profile");
  } else if (
    data?.userData?.firstLogin == 0 &&
    data?.userData?.approvalStatus == "APPROVED"
  ) {
    let userRole = localStorage.getItem("role");
    let authToken = localStorage.getItem("auth");
    let designation = data?.userData?.designation || "";
    localStorage.clear();
    // window.location.href = `http://localhost:3001?role=${userRole}&auth=${authToken}`;
    window.location.href = `https://admin.godenty.com?role=${userRole}&auth=${authToken}&designation=${designation}`;
  }
};
