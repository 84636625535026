import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "../../api/index";
import closeBtn from "../../Assets/closeBtn.svg";
import cloudAdd from "../../Assets/cloudAdd.png";
import pdfIcon from "../../Assets/pdf.svg";
import { getToken } from "../../helper/authHelper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddClinicDialog = ({
  open,
  handleClose,
  setOpen,
  handleClickOpen,
  handleCloseSubmit,
  header,
  clinicInformation,
  setInformationUpdateModal,
}) => {
  const [updateClinicData, setUpdateClinicData] = useState({
    clinicName: "",
    // clinicHeadName: "",
    // contactNumber: "",
    // emailAddress: "",
    website: "",
    address: "",
    googleMapLocation: "",
    area: "",
  });

  const [isUploading, setIsUploading] = useState(false);

  // Update state when the received prop changes
  useEffect(() => {
    if (clinicInformation?.clinicName) {
      setUpdateClinicData({
        clinicName: clinicInformation.clinicName,
        // clinicHeadName: "",
        // contactNumber: "",
        // emailAddress: "",
        website: clinicInformation.website,
        address: clinicInformation.address,
        area: clinicInformation?.area || "",
        googleMapLocation: clinicInformation.googleMapLocation,
      });
    }
  }, [clinicInformation?.clinicName]);

  const [certificate, setCertificate] = useState(null);
  const [licenseUrl, setLicenseUrl] = useState(null);

  const [progress, setProgress] = useState(0);

  const closeImage = () => {
    //console.log("called");
    setCertificate(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateClinicData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmitModal = async () => {
    if (
      updateClinicData.clinicName == "" ||
      // clinicHeadName: "",
      // contactNumber: "",
      // emailAddress: "",
      updateClinicData.website == "" ||
      updateClinicData.address == "" ||
      updateClinicData.googleMapLocation == "" ||
      updateClinicData.area == ""
    ) {
      return;
    }

    if (!licenseUrl) {
      toast.error("Please Upload the License");
      return;
    }

    // const headers = {
    //   Authorization: getToken(),
    //   "Content-Type": "application/json",
    // };
    const body = { ...updateClinicData, licenseUrl };

    // console.log(body);
    // return;

    try {
      let res = await api.updateClinicData(body);
      console.log(res);

      if (res?.data.success) {
        setInformationUpdateModal(true);
        handleClose();
      }
    } catch (e) {
      // console.log(e?.response?.data);
    }
  };

  const checkDisabled = () => {
    if (
      updateClinicData.clinicName == "" ||
      updateClinicData.website == "" ||
      updateClinicData.address == "" ||
      updateClinicData.googleMapLocation == "" ||
      updateClinicData.area == "" ||
      !licenseUrl
    ) {
      return true;
    }

    return false;
  };

  const handleFileUpload = async (e) => {
    setIsUploading(true);
    setCertificate(e.target.files[0]);
    let fileName = e.target.files[0].name;
    let file = e.target.files[0];

    let awsSUrl = `https://godenty-docs.s3.ap-south-2.amazonaws.com/docs/${fileName}`;

    let response = await fetch(awsSUrl, {
      method: "PUT",
      body: file,
    });
    setIsUploading(false);

    if (response.ok) {
      setLicenseUrl(response?.url);
    }
  };

  const handleCancel = () => {
    if (!clinicInformation?.clinicName) {
      setUpdateClinicData((prev) => {
        return {
          ...prev,
          clinicName: "",
          website: "",
          address: "",
          area: "",
          googleMapLocation: "",
        };
      });
    }

    if (clinicInformation?.clinicName) {
      let {
        address,
        googleMapLocation,
        licenseUrl,
        website,
        clinicName,
        name,
        phoneNumber,
        email,
        area,
      } = clinicInformation;

      setUpdateClinicData({
        address,
        googleMapLocation,
        licenseUrl,
        website,
        clinicName,
        name,
        phoneNumber,
        email,
        area,
      });
    }

    handleClose();
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            borderRadius: "40px",
            //  background: "#DBDBDB",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontWeight: 600,
            fontSize: "26px",
            color: "#182E4B",
          }}
          id="customized-dialog-title"
        >
          {header ? header : "Add"} - Clinic Information
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Name"
            onChange={handleChange}
            name="clinicName"
            value={updateClinicData.clinicName}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          {/* <OutlinedInput
            fullWidth
            placeholder="Enter Name Of Clinic Head"
            onChange={handleChange}
            name="clinicHeadName"
            value={updateClinicData.clinicHeadName}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          /> */}
          {/* <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Contact Number"
            onChange={handleChange}
            name="contactNumber"
            value={updateClinicData.contactNumber}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          /> */}
          {/* <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Email ID "
            onChange={handleChange}
            name="emailAddress"
            value={updateClinicData.emailAddress}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          /> */}
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Address"
            onChange={handleChange}
            name="address"
            value={updateClinicData.address}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Area"
            onChange={handleChange}
            name="area"
            value={updateClinicData.area}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Website"
            onChange={handleChange}
            name="website"
            value={updateClinicData.website}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <OutlinedInput
            fullWidth
            placeholder="Enter Clinic Geo Location"
            onChange={handleChange}
            name="googleMapLocation"
            value={updateClinicData.googleMapLocation}
            sx={{
              borderRadius: 2,
              border: "1px solid #DBDBDB",
              mb: 1.5,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                border: "none",
                outline: "none",
                height: "15px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          {!certificate ? (
            <Card
              sx={{
                background: "#FFFFFF",
                borderRadius: "12px",
                p: 5,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={cloudAdd}
                style={{ height: "46px", width: "46px" }}
                alt="cloud-icon"
              />
              <Typography
                variant="h3"
                sx={{ color: "#A5A5A5", fontWeight: 500, fontSize: "18px" }}
              >
                Drag and drop Council Reg Certificate here
              </Typography>

              <Typography
                variant="h3"
                sx={{
                  color: "#182E4B",
                  fontWeight: 400,
                  fontSize: "18px",
                  marginTop: "10px",
                }}
              >
                Or
              </Typography>

              <input
                type="file"
                accept="application/pdf"
                style={{
                  textAlign: "center",
                  opacity: 0,
                  position: "relative",
                  left: "10px",
                  top: "30px",
                  width: "250px",
                  height: "30px",
                }}
                // onChange={(e) => {
                //   handleFileUpload
                //   // console.log(e.target.files[0]);
                //   setCertificate(e.target.files[0]);
                //   // let data = URL.createObjectURL(e.target.files[0]);
                //   // console.log(data);
                //   // setCertificate(data);
                // }}
                onChange={handleFileUpload}
              />
              <div
                style={{
                  border: "2px solid blue ",
                  padding: "10px",
                  borderRadius: "12px",
                }}
              >
                Browse Council Reg Certificate
              </div>
            </Card>
          ) : (
            <Card
              sx={{
                minWidth: 275,
                minHeight: 150,
                // maxWidth: 275,
                maxHeight: 100,
                mt: 4,
                position: "relative",
                // left: "25%",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  maxHeight: "50px",
                  // justifyContent: "space-between",
                  // gap: "10px",
                }}
              >
                <img
                  src={pdfIcon}
                  style={{
                    minHeight: "16%",
                    maxHeight: "16%",
                    minWidth: "10% ",
                    maxWidth: "10% ",
                  }}
                ></img>
                <p
                  style={{
                    marginTop: "-2px",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Licence
                </p>
              </CardContent>
              <CardContent>
                <img
                  src={closeBtn}
                  onClick={closeImage}
                  style={{
                    minHeight: "16%",
                    maxHeight: "16%",
                    minWidth: "10% ",
                    maxWidth: "10% ",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                ></img>
              </CardContent>
              
              {/* <CardContent sx={{ maxHeight: 10 }}> */}
              <div
                style={{
                  maxHeight: "60px",
                  overflow: "hidden",
                  marginTop: "-10px",
                  // padding: "5px",
                  borderRadius: "20px",
                  width: "100%",
                }}
              >
                {certificate ? (
                  <img
                    src={certificate}
                    style={{
                      minHeight: "6%",
                      maxHeight: "6%",
                      // minWidth: "80% ",
                      // maxWidth: "80% ",
                      borderRadius: "10px",
                      border: "2px solid black",
                      marginTop: "-40px",
                      boxSizing: "content-box",
                      overflow: "hidden",
                    }}
                  ></img>
                ) : (
                  ""
                )}
                {/* </CardContent> */}
              </div>
            </Card>
          )}
          {/* <Box sx={{ width: "100%" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box> */}
        </DialogContent>
        <DialogActions
          sx={{
            padding: 0,
            display: "flex",
            justifyContent: "flex-end",
            pr: 3,
          }}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            variant="contained"
            size="medium"
            disabled={checkDisabled()}
            sx={{
              borderRadius: "12px",
              backgroundColor: "#0079BF",
              color: "#FFFFFF",
            }}
            onClick={handleSubmitModal}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddClinicDialog;
