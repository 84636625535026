import BdsMdsHeader from "./BdsMdsHeader"
import BdsProfile from "../BdsProfile/BdsProfile"
import "./styles.css"


const BdsGraduteFisrtLoginPage=()=>{

    return(<div className="homecontainerFirstLoginPage">
        <BdsMdsHeader/>
        <BdsProfile/>

    </div>)

}
export default BdsGraduteFisrtLoginPage