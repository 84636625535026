import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import api from "../../../api/index";
import closeBtn from "../../../Assets/closeBtn.svg";
import cloudAdd from "../../../Assets/cloudAdd.png";
import pdfIcon from "../../../Assets/pdf.svg";
import { getToken } from "../../../helper/authHelper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const EducationModal = ({
  open,
  handleClose,
  savedData,
  setSavedData,
  collegeName,
  setCollegeName,
  passedOutYear,
  setPassedOutYear,
  // setUploadedFile,
  // uploadedFile,
  // onSaveUploadedFile
  onPdfUpload,
  addModal,
  educationModalError,
  setEducationInformationUpdated,
  formDataDetails,
}) => {
  const [certificate, setCertificate] = useState(null);
  const [selectedYear, setSelectedYear] = useState("");
  const currentYear = new Date().getFullYear();
  const [collegeNameError, setCollegeNameError] = useState("");
  const [passedOutYearError, setPassedOutYearError] = useState("");
  const years = Array.from({ length: 100 }, (_, index) => currentYear - index);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [certificateUrl, setCertificateUrl] = useState(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);
    setCertificate(file);
    let fileName = e.target.files[0].name;

    let awsSUrl = `https://godenty-docs.s3.ap-south-2.amazonaws.com/docs/${fileName}`;

    let response = await fetch(awsSUrl, {
      method: "PUT",
      body: file,
    });
    console.log("res: ", response);
    if (response.ok) {
      setCertificateUrl(response?.url);
    }
  };
  const handleSave = async () => {
    const newData = {
      collegeName,
      passOutYear: passedOutYear,
      certificateUrl: certificateUrl,
    };
    setSavedData(newData);

    if (!collegeName || collegeName == "") {
      setCollegeNameError("Enter College Name");
      return;
    }
    if (!passedOutYear || passedOutYear == "") {
      setPassedOutYearError("Enter Passed Out Year");
      return;
    }

    if (!selectedFile) {
      setFileError("Upload Council Reg Certificate");
      return;
    }

    if (!certificateUrl) {
      setFileError("Upload Council Reg Certificate");
      return;
    }

    if (selectedFile) {
      onPdfUpload(selectedFile);
    }

    if (collegeName && passedOutYear && selectedFile) {
      handleClose();
      setCollegeNameError("");
      setPassedOutYearError("");
      setFileError("");
      // setCollegeName(""); // Clearing input values after successful save
      // setPassedOutYear("");
      // setSelectedFile(null);
      // setCertificate(null);
    }

    // const headers = {
    //   Authorization: getToken(),
    //   "Content-Type": "application/json",
    // };

    const body = { educationalInfo: newData };
    try {
      let res = await api.updateClinicData(body);
      if (res?.data.success) {
        setEducationInformationUpdated(true);
        educationModalError(null);
        window.location.reload();
      } else if (!res?.data.success) {
        educationModalError(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closeImage = () => {
    setCertificate("");
  };

  const handleCancel = () => {
    handleClose();
    if (!formDataDetails?.userDetails?.collegeName) {
      setCollegeName("");
      setPassedOutYear("");
    }
    if (formDataDetails?.userDetails?.collegeName) {
      let collegeName = formDataDetails.userDetails.collegeName;
      let passOutYear = formDataDetails?.userDetails?.passOutYear;

      if (collegeName) setCollegeName(collegeName);
      if (passOutYear) setPassedOutYear(passOutYear);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            borderRadius: "40px",
            backgroundColor: "#fcfcfc",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontWeight: 600,
            fontSize: "26px",
            color: "#182E4B",
          }}
          id="customized-dialog-title"
        >
          Edit - Dental Education Information
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div style={{ marginBottom: "10px" }}>
            <OutlinedInput
              fullWidth
              placeholder="Enter College Name"
              value={collegeName}
              onChange={(e) => {
                setCollegeName(e.target.value);
                setCollegeNameError(e.target.value ? "" : "Enter College Name");
              }}
              // onChange={(e) => {
              //   setCollegeName('clinicName', e.target.value);
              //   setCollegeNameError(e.target.value ? "" : "Enter College Name");
              // }}
              sx={{
                borderRadius: 3,
                border: "2px solid #DBDBDB",
                color: "#666666",
                marginBottom: "4px",
                backgroundColor: "white",
                "& .MuiOutlinedInput-input": {
                  borderRadius: 2,
                  border: "none",
                  outline: "none",
                  height: "15px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: 2,
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            />
            {collegeNameError && (
              <Box sx={{ color: "red", fontSize: "12px" }}>
                {collegeNameError}
              </Box>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Box>
              <div>
                <FormControl
                  fullWidth
                  sx={{
                    borderColor: "#DBDBDB",
                    color: "#a2a2a2",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 3,
                      color: "#a2a2a2",
                      borderColor: "#DBDBDB",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#DBDBDB",
                        color: "#a2a2a2",
                        borderRadius: 3,
                      },
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Year Of Graduation{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={passedOutYear}
                    label="Select Year Of Graduation  "
                    onChange={(e) => {
                      setPassedOutYear(e.target.value);
                      setPassedOutYearError(
                        e.target.value ? "" : "Enter PassedOut Year"
                      );
                    }}
                    // onChange={(e) => {
                    //   setPassedOutYear('passedOutYear', e.target.value);
                    //   setCollegeNameError(e.target.value ? "" : "Enter PassedOut Year");
                    // }}
                  >
                    {years.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {passedOutYearError && (
                  <Box
                    sx={{ color: "red", fontSize: "12px", marginTop: "8px" }}
                  >
                    {passedOutYearError}
                  </Box>
                )}
              </div>
            </Box>
          </div>

          {!certificate ? (
            <div>
              <Box
                sx={{
                  background: "#FFFFFF",
                  borderRadius: "12px",
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  border: "2px dashed #dbdbdb",
                }}
              >
                <img
                  src={cloudAdd}
                  style={{ height: "46px", width: "46px" }}
                  alt="cloud-icon"
                />
                <Typography
                  variant="h3"
                  sx={{ color: "#A5A5A5", fontWeight: 500, fontSize: "18px" }}
                >
                  Drag and drop
                  <span
                    style={{
                      color: "#182E4B",
                      fontWeight: 400,
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    Council Reg Certificate here
                  </span>
                </Typography>

                <Typography
                  variant="h3"
                  sx={{
                    color: "#A5A5A5",
                    fontWeight: 400,
                    fontSize: "18px",
                    marginTop: "10px",
                  }}
                >
                  Or
                </Typography>

                <input
                  // type="file"
                  // accept="application/pdf"
                  style={{
                    textAlign: "center",
                    opacity: 0,
                    position: "relative",
                    left: "10px",
                    top: "30px",
                    width: "250px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  // onChange={(e) => {
                  //   let data = URL.createObjectURL(e.target.files[0]);
                  //   console.log(data);
                  //   setCertificate(data);
                  // }}
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                />

                <div
                  style={{
                    border: "2px solid #218ac7  ",
                    padding: "10px",
                    borderRadius: "12px",
                    color: "#218ac7 ",
                  }}
                >
                  Browse Council Reg Certificate
                </div>
              </Box>

              {fileError && (
                <Box sx={{ color: "red", fontSize: "12px" }}>{fileError}</Box>
              )}
            </div>
          ) : (
            <Card
              sx={{
                minWidth: 275,
                minHeight: 50,
                maxWidth: 275,
                maxHeight: 100,
                mt: 4,
                position: "relative",
                left: "25%",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  maxHeight: "50px",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={pdfIcon}
                  style={{
                    height: "20px",
                  }}
                ></img>
                <p
                  style={{
                    marginTop: "-2px",
                    fontSize: "14px",
                  }}
                >
                  {fileName}
                </p>
                <img
                  src={closeBtn}
                  onClick={closeImage}
                  style={{
                    height: "20px",
                  }}
                ></img>
              </CardContent>
            </Card>
          )}
        </DialogContent>
        <DialogActions
          sx={{ padding: 0, display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={handleCancel} style={{ fontWeight: "bold" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "12px",
              backgroundColor: "#0079BF !important",
              color: "#FFFFFF",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EducationModal;
