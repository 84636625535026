import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Box, Grid, Button } from "@mui/material";
import BdsProfileInformation from "../BdsProfile/BdsProfileInformation";
import BdsInformation from "../BdsProfile/BdsInformation";
import logout from "../../Assets/logout.png";
import api from "../../api";
import { getToken } from "../../helper/authHelper";
function BdsProfile() {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const getUserDetail = async () => {
      // let authToken = getToken();
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: authToken,
      // };
      try {
        let res = await api.getUserDetail();
        if (res?.data?.success) {
          console.log(res.data.data.user);
          setFormData(res.data.data.user);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getUserDetail();
  }, []);

  return (
    //navbar
    <>
      <Grid
        container
        spacing={1}
        gap="20px"
        sx={{ p: 3, mt: "6em" }}
        justifyContent="center"
      >
        <Grid item width={399}>
          <BdsProfileInformation />
        </Grid>
        <Grid item width={743}>
          <BdsInformation formDataDetails={formData} />
        </Grid>
      </Grid>
    </>
  );
}

export default BdsProfile;
