import React from "react";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { List, ListItem, ListItemText, Box } from "@mui/material";
import { Link } from "react-router-dom";
const TermsAndConditions = () => {
  return (
    <Box
      sx={{
        padding: { xs: "20px", sm: "30px", md: "20px 50px", lg: "30px 80px" },
      }}
    >
      <div style={{ display: "flex " }}>
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/signup">
            {" "}
            <ArrowBackIcon />
          </Link>
        </div>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", padding: "10px 0px", marginLeft: "10px" }}
        >
          {" "}
          Terms and Conditions
        </Typography>
      </div>
      <List component="ol">
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  1. Acceptance of Terms:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  By using the Go Denty mobile app, you agree to be bound by
                  these Terms and Conditions, our Privacy Policy, and any other
                  guidelines or rules applicable to specific services offered by
                  Go Denty.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {" "}
                  2. Services Provided:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Go Denty is a platform that connects dental clinics with
                  skilled BDS and MDSdoctors to provide solutions for staffing,
                  clinic space, dental chairs, and equipment. The platform also
                  offers opportunities and consultations for dental graduates,
                  aiming to foster collaboration, career growth, and operational
                  efficiency in the dental community.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  3. User Responsibilities:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Users of the Go Denty app are responsible for providing
                  accurate and up-to-date information about their dental clinics
                  or professional qualifications. Users are also responsible for
                  maintaining the security of their accounts and for all
                  activities that occur under their account.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  5. Fees and Payments:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Any fees or payments associated with the use of Go Denty's
                  services will be clearly communicated to the users. Users are
                  responsible for all fees and payments incurred through the use
                  of the platform.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {" "}
                  6. Intellectual Property:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  All content and intellectual property found on the Go Denty
                  app,including but not limited to logos, designs, and software,
                  are the property of Go Denty. Users maynot use, reproduce, or
                  distribute any of the content without prior written consent
                  from Go Denty.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  7. Termination:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Go Denty reserves the right to terminate or suspend access to
                  the app for any user who violates these Terms and Conditions
                  or engages in any activity that is deemed harmful to the
                  platform or its users.
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  8. Limitation of Liability:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Go Denty is not liable for any indirect, incidental, special,
                  consequential, or punitive damages, or any loss of profits or
                  revenues, whether incurred directly or indirectly, or any loss
                  of data, use, goodwill, or other intangible losses, resulting
                  from (a) your access to, use of, inability to access, or
                  inability to use Go Denty; (b) any third parties.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#767980", marginTop: "10px" }}
                >
                  conduct or content on Go Denty, including any defamatory,
                  offensive, or illegal conduct of third parties. Godenty is
                  also not liable for any damages caused by BDS/ MDS doc or its
                  staff to clinic or its infrastructure during their visit to
                  Clinic. We are just a online platform who connects Dentists
                  with clinics. We do not take any responsibility or claim.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {" "}
                  9.Quality of Clinics:{" "}
                </Typography>
                <Typography variant="body1" sx={{ color: "#767980" }}>
                  Go Denty is a technology online platform that facilitates
                  connections between BDS and MDSdoctors and dental clinics.
                  While Go Denty takes care in the selection and verification of
                  clinics, it does not guarantee the quality of clinical
                  equipment or infrastructure provided by the clinics. Users are
                  advised to exercise their own discretion and judgment when
                  choosing to engage with any clinic through the platform. Go
                  Denty shall not be held liable for any dissatisfaction or
                  issues related to the quality of clinical equipment or
                  infrastructure provided by the clinics.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#767980", marginTop: "10px" }}
                >
                  By using the Go Denty mobile app and website, you acknowledge
                  that you have read, understood, and agree to be bound by these
                  Terms and Conditions. If you do not agree to these Terms and
                  Conditions, please do not use the Go Denty app and site.{" "}
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default TermsAndConditions;
