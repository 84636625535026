import React from "react";
import { useState, useEffect, useRef } from "react";
import "../App.css";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import navbarImg from "../Assets/GoLogo.png";
import footerText from "../Assets/Footertext.png";
import signUpImg from "../Assets/signUpButtonIMg.png";
// import aboutUsImage from "../Assets/AboutusImage.png";
import ClinicianComponent from "./ClinicianComponent";
import BdsGraduateComponent from "./BdsGraduateComponent";
import MdsGraduateComponent from "./MdsGraduatecomponent";
// import opacityImage1 from "../Assets/opacityImage1.png";
// import opacityImage2 from "../Assets/opacityImage2.png";
import goDentyImage from "../Assets/godentyImage.png";
import clinicianLogo from "../Assets/CliniciannewImage.png";
import mdslogo from "../Assets/mdsNewImage.png";
import bdslogo from "../Assets/BDSImage.png";
import clinicianOpacityImage from "../Assets/ClinicianOpacityImage.png";
import bdsOpacityImage from "../Assets/BdsOpacityimage.png";
import mdsOpacityImage from "../Assets/MdsOpacityImage.png";
import ourVissionimage from "../Assets/ourVissionimage.png";
import ourMissionImage from "../Assets/ourMissionimage.png";
import component1img from "../Assets/Component 1.png";
import aboutUslogo from "../Assets/aboutuslogo.png";
import middleSigninbtn from "../Assets/middle-signinbutn.png";
import signupArrow from "../Assets/signupArrowIcon.png";
import arrow from "../Assets/arrow.png";
import { Link } from "react-router-dom";

const GodentyLandingPage = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState(1);
  const [centralImage, setCentralImage] = useState(clinicianLogo);
  const [changeTabAutomatically, setChangeTabAutomatically] = useState(true);

  const muchMoreList = [
    { id: 1, value: "Treatment plan consultation" },
    { id: 2, value: "Marketplace" },
    { id: 3, value: "Dental Assistant Hiring" },
    { id: 4, value: "Treatment plan consultation" },
    { id: 5, value: "Service" },
    { id: 6, value: "Pinned Notification" },
    { id: 7, value: "Pinned Dental Assistant Training" },
    { id: 8, value: "Dental Assistant Hiring" },
    { id: 9, value: "Dental Assistant Hiring" },
    { id: 10, value: "Dental Assistant Hiring" },
    { id: 11, value: "Service" },
  ];
  const getCurrentImage = () => {
    const currentTab = tabsList.find((tab) => tab.id === currentActiveTab);
    return currentTab ? currentTab.image : null;
  };
  const tabsList = [
    { id: 1, value: "Clinician", route: "clinician", image: clinicianLogo },
    {
      id: 2,
      value: "MDS Graduate",
      route: "mds-graduate",
      image: mdslogo,
    },
    {
      id: 3,
      value: "BDS Graduate",
      route: "bds-graduate",
      image: bdslogo,
    },
  ];
  const opacityImages = {
    1: clinicianOpacityImage,
    2: mdsOpacityImage,
    3: bdsOpacityImage,
  };
  const currentActiveTabContent = () => {
    return (
      <div>
        <ClinicianComponent currentActiveTab={currentActiveTab} />
        <MdsGraduateComponent currentActiveTab={currentActiveTab} />
        <BdsGraduateComponent currentActiveTab={currentActiveTab} />
      </div>
    );
  };

  const handleImageClick = (image, tabId) => {
    setCentralImage(image);
    setCurrentActiveTab(tabId);
  };

  const handlePrevious = () => {
    setChangeTabAutomatically(false);
    if (currentActiveTab !== 1) {
      setCurrentActiveTab((prev) => {
        const prevIndex = prev - 1;
        setCentralImage(tabsList[prevIndex - 1].image);
        return prevIndex;
      });
    } else {
      let currImg = tabsList[2].image;
      setCurrentActiveTab(3);
      setCentralImage(currImg);
    }
  };
  const handlexNext = () => {
    setChangeTabAutomatically(false);
    if (currentActiveTab !== 3) {
      setCurrentActiveTab((prev) => {
        const nextIndex = prev + 1;
        setCentralImage(tabsList[nextIndex - 1].image);
        return nextIndex;
      });
    } else {
      let currImg = tabsList[0].image;
      setCurrentActiveTab(1);
      setCentralImage(currImg);
    }
  };

  useEffect(() => {
    if (changeTabAutomatically) {
      let id = setInterval(() => {
        if (currentActiveTab !== 3)
          setCurrentActiveTab((prev) => {
            const nextIndex = prev + 1;
            setCentralImage(tabsList[nextIndex - 1].image);
            return nextIndex;
          });
        else {
          let currImg = tabsList[0].image;
          setCurrentActiveTab(1);
          setCentralImage(currImg);
        }
      }, 3000);
      return () => clearInterval(id);
    }
  }, [currentActiveTab]);

  const containerRef = useRef(null);
  const handleScroll = () => {
    if (changeTabAutomatically) {
      setChangeTabAutomatically(false);
    }
  };

  useEffect(() => {
    const container = containerRef?.current;
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="godenty-container" ref={containerRef}>
      <div className="godenty-navbar">
        <img src={goDentyImage} alt="navbarimg" className="goLogImg" />
        <div style={{ display: "flex", width: "auto", marginRight: "15px" }}>
          <Link to="/signup" style={{ marginRight: "10%" }}>
            <div style={{alignItems:'center',justifyContent:'center',display:'flex'}}>
            <button className="signup-button">Sign up</button>
            </div>
          </Link>
          <Link to="/login" style={{ marginRight: "10%" }}>
            <button className="signup-button">Login</button>
          </Link>
        </div>
      </div>
      <div className="top-section-background top-section-position">
        <div className="top-section-card">
          <h1 className="headings-h1">
            {currentActiveTab === 2 ? (
              <>
                <span className="span-color">Flourish</span> Your <br />
                Careers &amp; <br />
                Expertise
              </>
            ) : currentActiveTab === 3 ? (
              <>
                Shape Your <br />
                Dental Careers
                <br />
                <span className="span-color">Effortlessly</span> <br />
              </>
            ) : (
              <>
                Connect, <br />
                Collaborate and <br />
                <span className="span-color">Excel</span>
              </>
            )}
          </h1>
          <img
            src={getCurrentImage()}
            alt="currentImage"
            loading="lazy"
            className="getCurrentImageClass"
          />
          <div className="navbar-opacityImgContainer">
            {tabsList
              .filter((tab) => tab.image !== centralImage)
              .map((tab, index) => (
                <div
                  className={`roundedcontainer${index === 1 ? " right-space" : ""
                    }`}
                  key={tab.id}
                >
                  <img
                    key={index}
                    src={opacityImages[tab.id]}
                    alt={`opacityImage${index + 1}`}
                    className="roundedcontainerOpacityimage"
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                    onClick={() => handleImageClick(tab.image, tab.id)}
                  />
                </div>
              ))}
          </div>
        </div>

        <img
          onClick={handlexNext}
          className="play-btn-right"
          src={arrow}
          style={{
            objectFit: "contain",
            width: "5%",
            transform: "rotate(180deg)",
            cursor: "pointer",
          }}
        />

        <img
          onClick={handlePrevious}
          className="play-btn-left"
          src={arrow}
          style={{
            objectFit: "contain",
            width: "5%",
            cursor: "pointer",
          }}
        />
      </div>
      <div className="containerHeight" style={{ position: "relative" }}>
        {currentActiveTabContent()}
      </div>
      <div className="muchMoreContainer">
        <h1 className="muchmore-heading">
          And Much
          <br /> <span className="span-color">More...</span>
        </h1>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "25px",
          }}
        >
          {muchMoreList.map((muchMoreValue) => (
            <div className="muchmoreListitem" key={muchMoreValue.id}>
              {muchMoreValue.value}
            </div>
          ))}
          {/* <div className="muchmoreListitem muchmore-marginleft">
            Equipment Repair
          </div>
          <div className="muchmoreListitem muchmore-marginright">
            Treatment plan consultation
          </div>
          <div className="muchmoreListitem muchmore-marginright">
            Dental Assistant Hiring
          </div> */}
        </div>
      </div>
      <div className="middle-yellowcontainer">
        <h2>
          Let’s shape the{" "}
          <span>
            future of dental
            <br /> healthcare,
          </span>{" "}
          together!
        </h2>
        <Link to="/signup">
          {/* <img src={middleSigninbtn} alt="middleSigninbtn" /> */}
          <button className="signup-login-btn">
            Signup{" "}/{" "}Login{" "}
            <img
              src={signupArrow}
              style={{ width: "41.5px", height: "16px" }}
            />
          </button>
        </Link>
      </div>
      <div className="about-vissionMissionContainer">
        <h1 className="body-headings">About Us</h1>
        <div className="about-vissionMissionCard">
          <div className="smallRectangleBg">
            <img src={aboutUslogo} alt="aboutUslogo" className="aboutUslogo" />
          </div>
          <p className="about-paragraph">
            We are the creators of Go Denty! A mobile app that connects dental
            clinic with skilled BDS and MDS doctors, offering solutions for
            staffing, clinic space, dental chair & equipment.    <br />
            <br />
            Our platform provides seamless access to opportunities and
            consultations for dental graduates, fostering collaboration, career
            growth, and operational efficiency in the dental community.
          </p>
        </div>
        <div className="about-vissionMissionCard">
          <div className="aboutSubvissionMissionCard">
            <h1 className="body-headings">Our Mission</h1>
            <p>
              Our mission is to foster a dental community where collaboration,
              innovation and professional growth drive. We aim to collectively
              contribute to the advancement of dental industry by creating an
              environment where clinics and  professionals' flourish.
            </p>
          </div>

          <img src={ourMissionImage} alt="ourMissionImage" />
        </div>

        <div className="about-vissionMissionCard ">
          <div className="aboutSubvissionMissionCard">
            <h1 className="body-headings">Our Vision</h1>
            <p>
              <span className="ourvissionspancolor">
                We strive to create a network where every dentist thrives, and
                every
              </span>{" "}
              <span className="ourvissionspanyellowcolor">
                clinic operates at its best.
              </span>
              <br />
              <br />
              We envision a dental industry where clinics effortlessly find the
              right professionals while also allowing them to make the best out
              of their clinic space and equipment. Alongside, BDS & MDS
              graduates can build fulfilling careers conveniently with Go Denty.
            </p>
          </div>
          <img src={ourVissionimage} alt="ourVissionimage" />
        </div>
      </div>
      <div className="godenty-footer-container">
        <div className="footer-div">
          <h2 className="footer-textnormalblue footer-textcommon">
            <span className="footr-texthighligtblue">Let’s shape the</span>{" "}
            future <br />
            of dental healthcare, <br />
            <span className="footr-texthighligtblue">together!</span>
          </h2>
          <Link style={{ cursor: "pointer" }} to="/signup">
            {/* <img src={signUpImg} alt="signup" className="footer-signUp" /> */}
            <button
              className="signup-login-btn footer-signUp"
              style={{ backgroundColor: "rgba(46, 88, 144, 1)" }}
            >
              Signup{" "}/{" "}Login{" "}
              <img
                src={signupArrow}
                style={{ width: "41.5px", height: "16px" }}
              />
            </button>
          </Link>
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: 'center',
            gap: { md: "30px" },
            justifyContent: { xs: "space-between", md: "normal" },
            padding: { xs: "30px", md: "20px 50px", lg: "30px 90px" },
          }}
        >
           <Link
            to="/Terms&Conditions"
            style={{ color: "#004E89", textDecoration: "underline" }}
          >
            {" "}
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", fontSize: "16px" }}
            >
              Terms & Conditions
            </Typography>
          </Link>
          <Link
            to="/refundPolicy"
            style={{ color: "#004E89", textDecoration: "underline" }}
          >
            {" "}
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", fontSize: "16px" }}
            >
              Refund Policy
            </Typography>
          </Link>
          <Link
            to="/privacyPolicy"
            style={{ color: "#004E89", textDecoration: "underline" }}
          >
            {" "}
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", fontSize: "16px" }}
            >
              Privacy Policy
            </Typography>
          </Link>
          <Link to="/ContactUs">
            <button style={{
              color: "white", backgroundColor: '#2e5890',fontWeight:'bold', border: 'none', height: '40px', width: '130px', borderRadius: '20px', fontSize: '15px', cursor: 'pointer'
            }}>Contact us</button>
          </Link>
        </Box>
      </div>
    </div>
  );
};

export default GodentyLandingPage;
