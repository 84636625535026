import React from 'react'
import MAIL from '../Assets/Vector (1).png'
import CALL from '../Assets/Vector.png'
import NAVBARICON from '../Assets/image 88.png'
import CONTACTUS from '../Assets/contactUS.png'
import LOCATION from '../Assets/location.png'
import LOGO from '../Assets/aboutuslogo.png'
function ContactUs() {
    return (
        <div>
            <div style={{ marginTop: '20px', marginLeft: '40px' }}>
                <img src={NAVBARICON} className='md:w-[150px] w-[100px]' />
            </div>
            <div class="lg:grid lg:grid-cols-5">
                <div class="col-span-2 mt-9  md:ml-12 ml-4">
                    <h1 className='md:text-4xl text-2xl font-bold text-[#2e5890]'>Contact Us</h1>
                    {/* <a href='tel:9381147184'> */}
                    <div className='bg-[#edf6fd] md:w-[88%] w-[96%] lg:h-[13%] md:h-[70px] h-[60px] lg:mt-9  md:mt-8 mt-3 rounded-[20px] items-center flex gap-4'>
                        <img src={CALL} className='md:w-[35px] w-[30px] pl-3'/>
                        <p className='text-[#2e5890] text-[20px] font-bold'>+919381147184</p>
                        
                    </div>
                    {/* </a>      */}

                    <a  href='https://mail.google.com/mail/?view=cm&fs=1&to=info@godenty.in'>
                    <div className='bg-[#edf6fd] md:w-[88%] w-[96%] lg:h-[13%] md:h-[70px] h-[60px] mt-9 rounded-[20px] items-center flex gap-4'>
                        <img src={MAIL} className='w-[35px] pl-3'/>
                        <p className='text-[#2e5890] text-[20px] font-bold'>info@godenty.in</p>

                    </div>
                    </a>
                    <div className='bg-[#edf6fd] md:w-[88%] w-[96%] lg:h-[13%] md:h-[70px] h-[60px] mt-9 rounded-[20px] items-center flex '>
                        <img src={LOGO} className='w-[60px] pl-3'/>
                        <p className='text-[#2e5890] text-[17px] font-bold pl-2'>GODENTY HEALTH TECH PRIVATE LIMITED</p>
                    </div>

                    <div className='bg-[#edf6fd] md:w-[88%] w-[96%] lg:h-[14%] md:h-[70px] h-[80px] mt-9 rounded-[20px] items-center flex gap-4'>
                        <img src={LOCATION} className='w-[35px] pl-3'/>
                        <p className='text-[#2e5890] text-[14px] font-bold'> 1-4-59, Padma shaali veedi, Karimnagar, Karimnagar-505001, Telangana.</p>

                    </div>
                    <div>

                    </div>
                </div>
                <div class="col-span-3 h-[80vh] " >
                    <img src={CONTACTUS} className='lg:h-[100%] h-[80%] w-[100%]' style={{objectFit:"contain", aspectRatio: 4/2}} />
                </div>

            </div>

        </div>
    )
}

export default ContactUs
