import { createMuiTheme, ThemeProvider } from "@mui/material/styles";
import React, { lazy } from "react";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ClinicProfile from "./components/ClinicProfile/ClinicProfile";
import ContactUs from "./components/ContactUs";
import GodentyLandingPage from "./components/GodentyLandingPage";
import GodentyLoginPage from "./components/GodentyLoginPage";
import GodentySignUpPage from "./components/GodentySignUpPage";
import BdsGraduteFisrtLoginPage from "./components/Homepages/BdsGraduteFisrtLoginPage";
import ClinicProfileFirstLoginPage from "./components/Homepages/ClinicProfileFirstLoginPage";
import MdsGraduateFisrtLoginPage from "./components/Homepages/MdsGraduateFirstLoginPage";
import NewpasswordComponent from "./components/LoginModules/Newpasswordcreatecomponent";
import PrivacyPolicy from "./components/policy/PrivacyPolicy";
import RefundPolicy from "./components/policy/RefundPolicy";
import TermsConditions from "./components/policy/Terms&Conditions";
import TermsAndConditions from "./components/policy/TermsAndConditions";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const VerifyOtp = lazy(() => import("./components/VerifyOtp"));

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "manrope", // Replace with the font you want to use
      "Arial", // Fallback font
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<GodentyLandingPage />} />
            <Route path="/signup" element={<GodentySignUpPage />} />
            <Route path="/bds/profile" element={<BdsGraduteFisrtLoginPage />} />
            <Route
              path="/mds/profile"
              element={<MdsGraduateFisrtLoginPage />}
            />

            <Route path="/verify-otp" element={<VerifyOtp />} />

            <Route
              path="/clinic/profile"
              element={<ClinicProfileFirstLoginPage />}
            />
            <Route path="/Terms&Conditions" element={<TermsConditions />} />
            <Route path="/login" element={<GodentyLoginPage />} />
            <Route path="/newpassword" element={<NewpasswordComponent />} />
            <Route path="/refundPolicy" element={<RefundPolicy />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route
              path="/termsAndConditions"
              element={<TermsAndConditions />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
      <ToastContainer hideProgressBar autoClose={1500} />
    </>
  );
};

export default App;
