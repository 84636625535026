import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";

import SuccessIcon from "../../Assets/success-tick.png";

const InformationUpdatedModal = ({
  showInformationUpdateModal,
  handleCloseInformationUpdateModal,
}) => {
  return (
    <div>
      <Dialog
        onClose={handleCloseInformationUpdateModal}
        aria-labelledby="customized-dialog-title"
        open={showInformationUpdateModal}
        fullWidth={true}
        maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            py: 5,
            overflowY: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseInformationUpdateModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box display={"flex"} justifyContent={"center"}>
            <img
              style={{
                height: "60px",
              }}
              src={SuccessIcon}
              alt="_success"
            />
          </Box>
          <Typography
            align="center"
            sx={{ fontSize: "30px", fontWeight: "600", color: "#F98247" }}
          >
            Successful
          </Typography>
          <Typography
            sx={{
              marginTop: "10px",
              fontSize: "16px",
              fontWeight: "400",
              color: "black",
            }}
            align="center"
          >
            Clinic Information updated successfully.
            <br />
            It is under review for approval.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InformationUpdatedModal;
