import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { Box, Grid, Button, CircularProgress } from "@mui/material";
import ProfileInformation from "./ProfileInformation";
import ClinicInformation from "./ClinicInformation";
import logout from "../../Assets/logout.png";
import BdsMdsHeader from "../../components/Homepages/BdsMdsHeader";
import { getToken } from "../../helper/authHelper";
import api from "../../api/index";

function ClinicProfile() {
  const [first, setFirst] = useState(true);
  const [loading, setLoading] = useState(true);
  const [clinicInformation, setClinicInformation] = useState({
    address: null,
    googleMapLocation: null,
    licenseUrl: null,
    website: null,
    clinicName: null,
    name: null,
    phoneNumber: null,
    email: null,
    area: null,
  });

  useEffect(() => {
    const getClininDetail = async () => {
      setLoading(true);

      try {
        let res = await api.getUserDetail();
        if (res?.data?.success) {
          let { name, phoneNumber, email } = res?.data?.data?.user;
          let {
            address,
            googleMapLocation,
            licenseUrl,
            website,
            clinicName,
            area,
          } = res?.data?.data?.user?.userDetails;

          if (
            address &&
            googleMapLocation &&
            licenseUrl &&
            website &&
            clinicName &&
            area
          ) {
            setClinicInformation({
              address,
              googleMapLocation,
              licenseUrl,
              website,
              clinicName,
              name,
              phoneNumber,
              email,
              area,
            });
            setFirst(false);
          }
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    getClininDetail();
  }, []);

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    //navbar
    <>
      <BdsMdsHeader />
      <Grid
        container
        spacing={1}
        gap="20px"
        sx={{ p: 3, marginTop: "100px" }}
        justifyContent="center"
      >
        <Grid item width={399}>
          <ProfileInformation />
        </Grid>
        <Grid item width={743}>
          <ClinicInformation
            first={first}
            clinicInformation={clinicInformation}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ClinicProfile;
