import { z } from "zod";

export const singUpschema = z
  .object({
    user_name: z
      .string()
      .min(3, { message: "Username must be at least 3 characters" }),
      // .max(20, { message: "Username cannot exceed 40 characters" }),
    user_email: z.string().email({ message: "Please enter a valid email" }),
    user_number: z
      .string()
      .length(10, { message: "Phone number must have exactly 10 digits" }),
    stateDentalCouncil: z.string({ message: "Please enter valid state" }),
    council_regnumber: z.string({
      message: "Please enter valid council registration number",
    }),
    password: z
      .string()
      .min(8, { message: "Password must be atleast 8 characters" })
      .max(12, { message: "Password should not exceed 12 characters" }),
    confirmPassword: z
      .string()
      .min(8, { message: "Confirm Password must be atleast 8 characters" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords doesn't match ",
    path: ["confirmPassword"],
  });
