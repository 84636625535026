import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import goDentyLogo from "../../Assets/godentyImage.png";
import api from "../../api/index";

const NewpasswordComponent = ({ email }) => {
  const [formData, setFormData] = useState({});
  const [inputErrors, setInputErrors] = useState({
    password: "",
    newPassword: "",
  });
  const navigate = useNavigate();

  const addFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setInputErrors({ ...inputErrors, [name]: "" });
  };

  const submitPassword = async () => {
    if (!formData.password || !formData.newPassword) {
      setInputErrors({
        password: formData.password ? "" : "Please enter password",
        newPassword: formData.newPassword
          ? ""
          : "Please enter confirm password",
      });
      return;
    }
    if (formData.password !== formData.newPassword) {
      setInputErrors({
        newPassword: "Passwords doesn't match",
      });
      return;
    }

    let body = { email: email, password: formData.newPassword };
    try {
      let response = await api.resetPassword(body);
      if (response?.data?.success) {
        setFormData({});
        navigate("/");
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        <div className="signUp-formcontainer">
          <h4>👋 Welcome to, Godenty </h4>
          <p style={{ color: "rgba(190, 190, 190, 1)" }}>
            Access your account by logging in.
          </p>
          <label style={{ fontWeight: "500" }}>NEW PASSWORD</label>
          <input
            style={{ marginBottom: "0px" }}
            className="signUp-formcontainer-input"
            name="password"
            type="password"
            onChange={addFormData}
            value={formData.password || ""}
            placeholder="Enter New Password  "
          />
          {inputErrors.password && (
            <p className="errorText">{inputErrors.password}</p>
          )}
          <br />
          <label style={{ fontWeight: "500" }}>CONFIRM PASSWORD</label>
          <input
            style={{ marginBottom: "0px" }}
            className="signUp-formcontainer-input"
            type="password"
            name="newPassword"
            value={formData.newPassword || ""}
            onChange={addFormData}
            placeholder="Enter Confirm password  "
          />
          {inputErrors.newPassword && (
            <p className="errorText">{inputErrors.newPassword}</p>
          )}
          <button
            style={{
              width: "100%",
              padding: "1rem",
              borderRadius: "12px",
              cursor: "pointer",
              border: "none",
              backgroundColor: "rgba(0, 121, 191, 1)",
              color: "white",
              marginTop: "15px",
              marginBottom: "15px",
              fontWeight: "bold",
            }}
            onClick={submitPassword}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default NewpasswordComponent;
