import React from "react";
import { Typography, Dialog, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function InformationUpdatedModal({
  showInformationUpdateModal,
  handleCloseInformationUpdateModal,
  formDataDetails,
}) {
  console.log(formDataDetails);
  return (
    <div>
      <Dialog
        onClose={handleCloseInformationUpdateModal}
        aria-labelledby="customized-dialog-title"
        open={showInformationUpdateModal}
        fullWidth={true}
        maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            width: "40%",
            maxWidth: "none",
            maxHeight: "50vh",
            overflowY: "auto",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseInformationUpdateModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
        //className={classes.dialogContent}
        >
          <Typography
            align="center"
            // className={classes.text}
            sx={{ fontSize: "40px", fontWeight: "600", color: "#00A01B" }}
          >
            Profile Updated
          </Typography>
          <Typography
            // align="center"
            //  className={classes.text}
            sx={{
              marginTop: "10px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            The MDS Information updated Successfully{" "}
            {formDataDetails?.approvalStatus !== "APPROVED" && (
              <span>It is under review for approval. </span>
            )}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InformationUpdatedModal;
