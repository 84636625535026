import mdsImage1 from "../Assets/Mdsimage1.png";
import mdsImage2 from "../Assets/MdsImage2.png";
import mdsImage3 from "../Assets/MdsImage3.png";
import mdsImage4 from "../Assets/MdsImage4.png";
// import m1 from "../Assets"
// import m2 from "../Assets"
// import m3 from "../Assets"
// import m4 from "../Assets"

const MdsGraduateComponent = ({ currentActiveTab }) => {
  const opacityClass = currentActiveTab === 2 ? "opacity" : "no-opacity";

  return (
    <div
      className={`middle-section-background-desktop ${opacityClass}`}
      //className={opacityClass}
    >
      <div className="middle-section-cards first-middle-section-card position-relative card-height">
        <div style={{ padding: "5%" }}>
          <h3>
            Book Your Ideal <br />
            Clinical Space 
          </h3>
          <p>
            We understand the difficulty of yours in finding and booking ideal
            clinical spaces for consultations, surgeries and procedures. You can
            choose available clinic spaces hourly or case wise at your preferred
            location.
          </p>
        </div>
        <div className="small-circle">1</div>
        <img src={mdsImage1} alt="rectangle" className="image-contain" />
      </div>
      <div className="middle-section-cards middle-section-cards-second position-relative card-height">
        <div style={{ padding: "6.5%" }}>
          <h3>
            Find Full-Time Job <br />
            Opportunities
          </h3>
          <p>
            Amidst plethora of job opportunities, it is exhausting to find a job
            that suits & fits you the best. Go Denty makes it is easy for you
            and clinics to find each other and make the best out of this
            collaboration.
          </p>
        </div>
        <div className="small-circle">2</div>
        <img src={mdsImage2} alt="rectaAngleImage2" className="image-contain" />
      </div>
      <div className="middle-section-cards position-relative card-height">
        <div style={{ padding: "5%" }}>
          <h3>
            Professional Consultation
            <br /> Opportunities
          </h3>
          <p>
            We are aware of the pivotal role network plays, especially in dental
            industry. With Go Denty, you have access to receiving consultation
            opportunities for specific procedures broadening your professional
            network and enhancing your experience.   
          </p>
        </div>
        <div className="small-circle">3</div>
        <img src={mdsImage3} alt="mdsImage3" className="image-contain" />
      </div>
      <div className="middle-section-cards middle-section-cards-second position-relative card-height">
        <div style={{ padding: "6.9%" }}>
          <h3>Buy clinic efficiently</h3>
          <p>
            Go Denty streamlines the process of buying a clinic, ensuring a
            smooth transition for all dentists involved. Connect with potential
            buyers or sellers, enabling an efficient and smooth practice
            transition. 
          </p>
        </div>
        <div className="small-circle">4</div>
        <img src={mdsImage4} alt="mdsImage4" className="image-contain" />
      </div>
    </div>
  );
};
export default MdsGraduateComponent;
