import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

import api from "../../../api/index";
import closeBtn from "../../../Assets/closeBtn.svg";
import cloudAdd from "../../../Assets/cloudAdd.png";
import pdfIcon from "../../../Assets/pdf.svg";
import { getToken } from "../../../helper/authHelper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const SpecialityModal = ({
  open,
  handleClose,
  setOpen,
  handleClickOpen,
  handleCloseSubmit,
  handleSubmitModal,
  speciality,
  onSelectedChange,
  addModal,
  selectedSpecialities,
  setSpeciaityInformationUpdated,
  formDataDetails,
}) => {
  const [certificate, setCertificate] = useState("");

  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (selectedSpecialities && selectedSpecialities.length > 0) {
      setSelected(selectedSpecialities);
    }
  }, [selectedSpecialities]);

  const newSelected = [...selected];
  const handleSpecialityClick = (selectedSpeciality) => {
    const newSelected = [...selected];
    if (newSelected.includes(selectedSpeciality)) {
      newSelected.splice(newSelected.indexOf(selectedSpeciality), 1);
    } else {
      newSelected.push(selectedSpeciality);
    }
    setSelected(newSelected);
  };
  const handleSave = async () => {
    if (selected.length === 0) {
      return;
    }

    onSelectedChange(newSelected);
    handleSubmitModal(selected);

    const body = { specialistIn: selected };

    // const headers = {
    //   Authorization: getToken(),
    //   "Content-Type": "application/json",
    // };
    try {
      let res = await api.updateClinicData(body);
      console.log(res);
      if (res?.data.success) {
        setSpeciaityInformationUpdated(true);
        window.location.reload();
        // window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closeImage = () => {
    console.log("called");
    setCertificate("");
  };

  const handleCancel = () => {
    handleClose();
    if (
      !formDataDetails?.userDetails?.specialistIn ||
      formDataDetails?.userDetails?.specialistIn?.length === 0
    ) {
      setSelected([]);
    }

    if (
      formDataDetails?.userDetails?.specialistIn &&
      formDataDetails?.userDetails?.specialistIn.length > 0
    ) {
      let specialistIn = formDataDetails.userDetails.specialistIn;
      setSelected(specialistIn.split(","));
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            borderRadius: "40px",
            backgroundColor: "#fcfcfc",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontWeight: 600,
            fontSize: "26px",
            color: "#182E4B",
          }}
          id="customized-dialog-title"
        >
          {!addModal ? "Edit" : "Add"} - Specialty In BDS
        </DialogTitle>
        <IconButton
          aria-label="close"
          //  onClick={handleClose}
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            variant="h6"
            style={{ marginBottom: "12px", color: "#000", fontSize: "15px" }}
          >
            Specialty In BDS
          </Typography>
          <div>
            {selected.length == 0 ? (
              <p style={{ color: "red" }}>
                please select atleast one specialty
              </p>
            ) : (
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {selected.map((select, index) => (
                  <Button
                    key={index}
                    sx={{
                      padding: "4px 8px",
                      border: "1px solid gray",
                      borderRadius: "20px",
                      color: "black",
                      fontSize: "12px",
                      background: "#80bcdf",
                    }}
                    onClick={() => handleSpecialityClick(select)}
                  >
                    {select}{" "}
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "12px",
                        display: "flex",
                      }}
                    >
                      {" "}
                      <CloseIcon sx={{ height: "16px" }} />
                    </span>
                  </Button>
                ))}
              </div>
            )}
          </div>

          <Typography
            variant="h6"
            style={{ margin: "12px 0px", color: "#000", fontSize: "15px" }}
          >
            Select Below to Add Specialty In BDS
          </Typography>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {speciality.map((special, index) => (
              <Button
                key={index}
                sx={{
                  padding: "4px 8px",
                  border: "1px solid gray",
                  borderRadius: "20px",
                  color: "black",
                  fontSize: "12px",
                }}
                onClick={() => handleSpecialityClick(special)}
              >
                {special}
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  {" "}
                  <AddIcon sx={{ height: "16px" }} />
                </span>
              </Button>
            ))}
          </div>
        </DialogContent>
        <DialogActions
          sx={{ padding: 0, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            // onClick={handleClose}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "12px",
              backgroundColor: "#0079BF !important",
              color: "#FFFFFF",
            }}
            onClick={handleSave}
          >
            {!addModal ? "Add" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SpecialityModal;
