import ClinicProfile from "../ClinicProfile/ClinicProfile"
import BdsMdsHeader from "../Homepages/BdsMdsHeader"
const ClinicProfileFirstLoginPage = () => {
  return (
    <div className="homecontainerFirstLoginPage">
      <BdsMdsHeader />
      <ClinicProfile/>
    </div>
  );
};
export default ClinicProfileFirstLoginPage;
