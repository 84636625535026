import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import api from "../../../api/index";
import { getToken } from "../../../helper/authHelper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ClinicModal = ({
  open,
  handleClose,
  // handleInputChange,
  formdata,
  setFormData,
  addModal,
  setClinicModalError,
  setExperienceInformationUpdated,
  setClinicalExperienceArray,
  clinicalExperienceArray,
  formDataDetails,
}) => {
  const [certificate, setCertificate] = useState("");
  const [clinicNameError, setClinicNameError] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [joinedmonthError, setMonthError] = useState("");
  const [joinedyearEror, setYearError] = useState("");
  const [departuremonth, setDepartureMonth] = useState("");
  const [departureYear, setDepartureYear] = useState("");
  const [localFormData, setLocalFormData] = useState({ ...formdata });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, index) => currentYear - index);

  const closeImage = () => {
    console.log("called");
    setCertificate("");
  };

  const handleChange = (e, indexArray) => {
    const { name, value } = e.target;

    setClinicalExperienceArray((prev) => {
      return prev.map((el, index) => {
        if (index === indexArray) {
          if (name === "departure") {
            if (value >= el.joinedYear) {
              return { ...el, [name]: value };
            } else {
              return { ...el };
            }
          } else return { ...el, [name]: value };
        } else return el;
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setClinicalExperienceArray((prev) => {
      return prev.map((element) => {
        let errors = {};

        if (!element.joinedYear) {
          errors.joinedYearError = "Enter Joined Year";
        }

        if (!element.joinMonth) {
          errors.joinMonthError = "Enter Joined Month";
        }

        if (!element.jobTitle) {
          errors.jobTitleError = "Enter Job Title";
        }

        if (!element.departureMonth) {
          errors.departureMonthError = "Enter Departured Month";
        }

        if (!element.departure) {
          errors.departureError = "Enter Departured Year";
        }

        if (!element.clinicName) {
          errors.clinicNameError = "Enter Clinic Name";
        }

        // Merge the current errors with the existing errors in the element
        return { ...element, ...errors };
      });
    });

    let arr = [...clinicalExperienceArray];

    for (let el of arr) {
      if (
        !el.joinedYear ||
        !el.departure ||
        !el.clinicName ||
        !el.departureMonth ||
        !el.jobTitle ||
        !el.joinMonth
      )
        return;
    }

    let clinicalExperiences = [];
    for (let el of arr) {
      let startFrom = `${el.joinMonth} ${el.joinedYear}`;
      let upto = `${el.departureMonth} ${el.departure}`;

      clinicalExperiences.push({
        startFrom,
        upto,
        clinicName: el.clinicName,
        jobTitle: el.jobTitle,
        id: el.id || null,
      });
    }

    const body = { clinicalExperiences };
    setClinicalExperienceArray((prev) => {
      return prev.map((element) => {
        return {
          ...element,
          clinicNameError: null,
          jobTitleError: null,
          joinMonthError: null,
          departureError: null,
          joinedYearError: null,
          departureMonthError: null,
        };
      });
    });

    // const headers = {
    //   Authorization: getToken(),
    //   "Content-Type": "application/json",
    // };

    try {
      let res = await api.updateClinicData(body);
      console.log(res);
      if (res?.data.success) {
        setExperienceInformationUpdated(true);
        setClinicModalError(null);
        window.location.reload();
      }
      if (!res?.data.success) {
        setClinicModalError(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }

    handleClose();
  };

  const handleAddArray = () => {
    let newObj = {
      clinicName: null,
      clinicNameError: null,
      jobTitle: null,
      jobTitleError: null,
      joinMonth: null,
      joinMonthError: null,
      departure: null,
      departureError: null,
      joinedYear: null,
      joinedYearError: null,
      departureMonth: null,
      departureMonthError: null,
    };
    setClinicalExperienceArray((prev) => {
      return [...prev, newObj];
    });
  };

  const handleDeleteArray = (indexArray) => {
    setClinicalExperienceArray((prev) => {
      return prev.filter((element, index) => {
        if (index !== 0) {
          return index !== indexArray;
        } else return element;
      });
    });
  };

  const handleCancel = () => {
    handleClose();
    if (
      !formDataDetails?.userDetails?.clinicalExpriences ||
      formDataDetails?.userDetails?.clinicalExpriences.length <= 1
    ) {
      setClinicalExperienceArray([
        {
          clinicName: null,
          clinicNameError: null,
          jobTitle: null,
          jobTitleError: null,
          joinMonth: null,
          joinMonthError: null,
          departure: null,
          departureError: null,
          joinedYear: null,
          joinedYearError: null,
          departureMonth: null,
          departureMonthError: null,
        },
      ]);
    }

    let experiences = [];
    if (
      formDataDetails?.userDetails?.clinicalExpriences &&
      formDataDetails?.userDetails?.clinicalExpriences.length > 0
    ) {
      formDataDetails.userDetails.clinicalExpriences?.forEach((el) => {
        const { clinicName, jobTitle, startFrom, upto, id } = el;

        const startDate = startFrom ? new Date(startFrom) : null;
        const endDate = upto ? new Date(upto) : null;

        // Format the start date
        const startMonthYear = startDate
          ? `${startDate.toLocaleString("en-US", {
              month: "long",
            })} ${startDate.getFullYear()}`
          : "";

        // Format the end date
        const endMonthYear = endDate
          ? `${endDate.toLocaleString("en-US", {
              month: "long",
            })} ${endDate.getFullYear()}`
          : "";

        let obj = {
          clinicName: clinicName || "",
          jobTitle: jobTitle || "",
          joinMonth: startMonthYear.split(" ")[0],
          joinedYear: startDate ? startDate.getFullYear() : "",
          departureMonth: endMonthYear.split(" ")[0],
          departure: endDate ? endDate.getFullYear() : "",
          clinicNameError: null,
          jobTitleError: null,
          joinMonthError: null,
          departureError: null,
          joinedYearError: null,
          departureMonthError: null,
          id,
        };

        experiences.push(obj);
      });
    }

    if (experiences.length > 0) {
      setClinicalExperienceArray(experiences);
    }
  };

  return (
    <>
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          // maxWidth="md"
          fullWidth
          sx={{
            "& .MuiDialog-paper": {
              padding: "20px",
              borderRadius: "40px",
              backgroundColor: "#fcfcfc",
            },
          }}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              fontWeight: 600,
              fontSize: "26px",
              color: "#182E4B",
              display: "flex",
              justifyContent: "space-between",
            }}
            id="customized-dialog-title"
          >
            <span>{!addModal ? "Add" : "Edit"} - Clinical Experience</span>
            <span onClick={handleAddArray}>
              <AddIcon />
            </span>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          {clinicalExperienceArray?.map((element, indexArray) => {
            return (
              <DialogContent sx={{ marginTop: "1.2rem" }}>
                <div style={{ marginBottom: "10px" }}>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                    onClick={() => handleDeleteArray(indexArray)}
                  >
                    <CloseIcon />
                  </div>
                  <OutlinedInput
                    fullWidth
                    placeholder="Enter Clinic Name"
                    value={clinicalExperienceArray[indexArray].clinicName}
                    name="clinicName"
                    onChange={(e) => handleChange(e, indexArray)}
                    sx={{
                      borderRadius: 3,
                      border: "2px solid #DBDBDB",
                      color: "#666666",
                      marginBottom: "4px",
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        border: "none",
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  />
                  {clinicalExperienceArray[indexArray].clinicNameError && (
                    <Box
                      sx={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                    >
                      {clinicalExperienceArray[indexArray].clinicNameError}
                    </Box>
                  )}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <OutlinedInput
                    fullWidth
                    placeholder="Enter Job Tittle"
                    value={clinicalExperienceArray[indexArray].jobTitle}
                    name="jobTitle"
                    onChange={(e) => handleChange(e, indexArray)}
                    sx={{
                      borderRadius: 3,
                      border: "2px solid #DBDBDB",
                      color: "#666666",
                      marginBottom: "4px",
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        border: "none",
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  />
                  {clinicalExperienceArray[indexArray].jobTitleError && (
                    <Box
                      sx={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                    >
                      {clinicalExperienceArray[indexArray].jobTitleError}
                    </Box>
                  )}
                </div>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={2}
                  marginBottom={2}
                >
                  <div style={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        borderColor: "#DBDBDB",
                        color: "#a2a2a2",
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 3,
                          color: "#a2a2a2",
                          borderColor: "#DBDBDB",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#DBDBDB",
                            color: "#a2a2a2",
                            borderRadius: 3,
                          },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Joined Month{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Joined Month  "
                        value={clinicalExperienceArray[indexArray].joinMonth}
                        name="joinMonth"
                        onChange={(e) => handleChange(e, indexArray)}
                      >
                        {months.map((month, index) => (
                          <MenuItem key={index} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {clinicalExperienceArray[indexArray].joinMonthError && (
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "4px",
                        }}
                      >
                        {clinicalExperienceArray[indexArray].joinMonthError}
                      </Box>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        borderColor: "#DBDBDB",
                        color: "#a2a2a2",
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 3,
                          color: "#a2a2a2",
                          borderColor: "#DBDBDB",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#DBDBDB",
                            color: "#a2a2a2",
                            borderRadius: 3,
                          },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Joined Year{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Joined Year"
                        value={clinicalExperienceArray[indexArray].joinedYear}
                        name="joinedYear"
                        onChange={(e) => handleChange(e, indexArray)}
                      >
                        {years.map((year, index) => (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {clinicalExperienceArray[indexArray].joinedYearError && (
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "4px",
                        }}
                      >
                        {clinicalExperienceArray[indexArray].joinedYearError}
                      </Box>
                    )}
                  </div>
                </Box>

                <Box display="flex" flexDirection="row" gap={2}>
                  <div style={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        borderColor: "#DBDBDB",
                        color: "#a2a2a2",
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 3,
                          color: "#a2a2a2",
                          borderColor: "#DBDBDB",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#DBDBDB",
                            color: "#a2a2a2",
                            borderRadius: 3,
                          },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Departure Month{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Departure Month  "
                        // value={formdata.departureMonth}
                        value={
                          clinicalExperienceArray[indexArray].departureMonth
                        }
                        name="departureMonth"
                        onChange={(e) => handleChange(e, indexArray)}
                      >
                        {months.map((month, index) => (
                          <MenuItem key={index} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {clinicalExperienceArray[indexArray]
                      .departureMonthError && (
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "4px",
                        }}
                      >
                        {
                          clinicalExperienceArray[indexArray]
                            .departureMonthError
                        }
                      </Box>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        borderColor: "#DBDBDB",
                        color: "#a2a2a2",
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 3,
                          color: "#a2a2a2",
                          borderColor: "#DBDBDB",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#DBDBDB",
                            color: "#a2a2a2",
                            borderRadius: 3,
                          },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Departure Year{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Departure Year  "
                        value={clinicalExperienceArray[indexArray].departure}
                        name="departure"
                        onChange={(e) => handleChange(e, indexArray)}
                      >
                        {years.map((year, index) => (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {clinicalExperienceArray[indexArray].departureError && (
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "4px",
                        }}
                      >
                        {clinicalExperienceArray[indexArray].departureError}
                      </Box>
                    )}
                  </div>
                </Box>
              </DialogContent>
            );
          })}
          <DialogActions
            sx={{ padding: 0, display: "flex", justifyContent: "flex-end" }}
          >
            <Button onClick={handleCancel} style={{ fontWeight: "bold" }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              sx={{
                borderRadius: "12px",
                backgroundColor: "#0079BF !important",
                color: "#FFFFFF",
              }}
              onClick={handleSubmit}
            >
              {!addModal ? "Add" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ClinicModal;
